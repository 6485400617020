import React, { useContext } from 'react';
import { TableCell, TableRow, TableHead, TableBody, Paper, TablePagination, Table, CircularProgress, Grid, TableContainer, IconButton, Tooltip, Dialog, DialogContent, DialogTitle, TextField, FormControl, Button, DialogActions, } from '@mui/material';
import { VehiclesContext } from './contexts/VehiclesContext';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Req } from '../functions';

export default function Vehicles()
{
    const { columns, dataLoaded, lines, getData } = useContext(VehiclesContext);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let navigate = useNavigate();
    const [addCar, setAddCar] = useState(false);
    const { control: addVehicle, handleSubmit: addNewVehicle, setError } = useForm({
        mode: "onChange",
    });

    const submitNewCar = async (data) => {
        await Req("admin/vehicles/add", "post", data).then((res) => {
            const success = res.success;

            if (!success)
            {
                const error = res.error;
                if (error === 'name_exists')
                    setError("name", {
                        type: "custom",
                        message: "שם הרכב שהוזן כבר נמצא בשימוש"
                    });
                return;
            }

            navigate('/vehicles/' + res['id']);
        });
    };

    const handleDelete = async (id, name) => {
        const conf = window.confirm("האם הינך בטוח שברצונך להסיר את רכב " + name + "?\nפעולה זו עלולה להסיר את כל נתונים הרכב (היסטוריית ביקורות, ציוותים, נתונים טכניים ומפרט טכני).");
        if (!conf) 
            return;

        await Req("admin/vehicles/delete/" + id, "get").then((res) => {
            const success = res.success;
            if (success) {
                getData();
                return;
            }
        });
    };

    return (
        <>
          <Grid container sx={{mb: 3}}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} textAlign={'end'}>
                  <Tooltip title={'הוספת רכב חדש'}><IconButton onClick={() => setAddCar(!addCar)}><Add /></IconButton></Tooltip>
              </Grid>
          </Grid>
          <Dialog open={addCar} onClose={() => setAddCar(!addCar)}>
            <form onSubmit={(e) => e.preventDefault()}>
                <DialogTitle>הוספת רכב חדש</DialogTitle>
                <DialogContent>
                    <Controller
                        control={addVehicle}
                        name="name"
                        rules={{required: true}}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <FormControl fullWidth sx={{mb: 2}}>
                                <TextField type={'text'} variant={'standard'} error={!!error} helperText={error ? error.message : ''} name={name} label={'שם הרכב'} InputLabelProps={{shrink: true}} onChange={onChange} value={value ?? ''} />
                            </FormControl>
                        )}
                    />
                    <DialogActions>
                        <Button type="submit" onClick={addNewVehicle((data) => submitNewCar(data))}>הוסף</Button>
                    </DialogActions>
                </DialogContent>
            </form>
          </Dialog>
          <Paper sx={{ width: '100%' }}>
          <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; רכבי כיבוי אש</TableCell>
                        </TableRow>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.label}
                                align={column.align}
                                style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : lines
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, i) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                {columns.map((column, o) => {
                                    const value = row[column.id];
                                    if (column.id === 'options')
                                    return (
                                        <TableCell key={o}>
                                            <Tooltip title={"עריכה"}>
                                                <IconButton onClick={() => navigate('/vehicles/' + row.id)}><Edit /></IconButton>
                                            </Tooltip>
                                            <Tooltip title={"מחיקה"}>
                                                <IconButton onClick={() => handleDelete(row.id, row.vehicle)}><Delete /></IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    );

                                    return (
                                        <TableCell key={o} align={column.align}>{column.id === 'vehicle' ? <>{value}<br /><img src={row.image_url} width={80} alt={value} /></> : column.id === 'chief' ? (row.chief === 1 ? <span style={{color: "green"}}>כן</span> : <span style={{color: "red"}}>לא</span>) : value}</TableCell>
                                    );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={lines.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"רשומות לעמוד: "} 
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                key={"search"}
            />
          </Paper>
        </>
    );
}

export { Vehicles };