import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from '@mui/material';
import { StorageContext } from './contexts/StorageContext';

const columns = [
  { id: 'id', label: 'מק"ט', minWidth: 80 },
  { id: 'name', label: 'שם פריט', minWidth: 170 },
  { id: 'itemInfo', label: 'תיאור פריט', minWidth: 100 },
  { id: 'category', label: 'קטגוריה', minWidth: 170 },
  { id: 'location', label: 'מיקום', minWidth: 100 },
  { id: 'manufacturer', label: 'יצרן', minWidth: 100 },
  { id: 'model', label: 'דגם', minWidth: 100 },
  { id: 'dateCheck', label: 'ת. בדיקה', minWidth: 120 },
  { id: 'dateNextCheck', label: 'ת. בדיקה הבאה', minWidth: 120 },
  { id: 'amount', label: 'כמות', minWidth: 80 },
  { id: 'amountMin', label: 'כמות מינ׳', minWidth: 100 },
  { id: 'status', label: 'סטטוס', minWidth: 100 },
  { id: 'note', label: 'הערות', minWidth: 100 },
  { id: 'time', label: 'שינוי אחרון', minWidth: 100 },
  { id: 'options', label: 'אפשרויות', minWidth: 160, },
];

function Storage() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const { rows, dataLoaded } = useContext(StorageContext);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

  return (
      <>
        <Paper sx={{ width: '100%' }}>
        <TableContainer>
            <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={15} sx={{fontSize: 20, color: "#e2122a"}}>&larr; ניהול מחסן</TableCell>
                    </TableRow>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!dataLoaded ? <TableRow><TableCell colSpan={15} align={"center"}><CircularProgress /></TableCell></TableRow> : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, e) => {
                            const value = row[column.id];
                            // console.log(row[column.id]);
                            return (
                                <TableCell align={column.align} key={e}>
                                    {column.id === 'time' ? <>{row.time.length ? row.time : ''}{row.time.length > 0 && row.nameChange.length > 0 && <br />}{row.nameChange.length ? <><span>{row.nameChange}</span></> : ''}</> : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"רשומות לעמוד: "} 
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} 
            key={"search"}
        />
        </Paper>
    </>
  );
}

export default Storage;