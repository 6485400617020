import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Grid } from '@mui/material';
import { BlockedContext } from './contexts/BlockedContext';
import SearchBar from '../components/SearchBar';

const columns = [
  { id: 'imei', label: 'IMEI', minWidth: 170 },
  { id: 'id_device', label: 'מזהה מכשיר', minWidth: 170 },
  { id: 'date', label: 'תאריך', minWidth: 220 },
  { id: 'ip_user', label: 'IP', minWidth: 220 },
  { id: 'options', label: 'אפשרויות' }
];

function Blocked() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { rows, dataLoaded, handleSearch, handleDelete } = useContext(BlockedContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

  return (
      <>
        <Grid container>
            <Grid item xs={6}>
                <SearchBar handleChange={(data) => handleSearch(data)} />
            </Grid>
        </Grid>
        <Paper sx={{ width: '100%' }}>
        <TableContainer>
            <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; מכשירים חסומים</TableCell>
                    </TableRow>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.label}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, o) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={o} align={column.align}>
                                {column.id === 'options' ? <Button variant={"outlined"} color={"error"}  onClick={() => handleDelete(row.id)}>מחיקה</Button> : value}</TableCell>
                            );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"רשומות לעמוד: "} 
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            key={"search"}
        />
        </Paper>
    </>
  );
}

export default Blocked;