import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Req } from '../../functions';

const SettingsContext = React.createContext();
const SettingsProvider = (props) => {
    const [dataLoaded, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const getData = async () => {
        setIsLoading(true);
        try {
            await Req("admin/settings", "get").then((res) => {
                const success = res.success;
                if (!success)
                    return;
                    
                setData(res.data);
                setIsLoading(false);
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    const onClick = async (data) => {
        try {
            setButtonDisabled(true);
            await Req("admin/settings", "post", data).then((res) => {
                const success = res.success;
                if (!success)
                    return;
                
                setButtonDisabled(false);
                getData()
            });
        }
        catch (error) {
            console.log(error);
            setButtonDisabled(false);
        }
    };
    
    useEffect(() => {
        getData();
    }, []);

    const [disabledButton, setButtonDisabled] = useState(false);
    const { control: settings, watch, handleSubmit, setValue } = useForm({ mode: "onChange" });

    return (
        <SettingsContext.Provider value={{
            getData,
            data,
            setData,
            dataLoaded,
            settings,
            watch,
            handleSubmit,
            onClick,
            setValue,
            disabledButton
        }}>
            {props.children}
        </SettingsContext.Provider>
    );
}

export { SettingsContext, SettingsProvider }