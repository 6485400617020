import React, { useState } from 'react';
import { AppBar, Box, Tooltip, CssBaseline, Divider, Button, Drawer, Avatar, Menu, MenuItem, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, ListSubheader } from '@mui/material';
import { Devices, Group, Warehouse, Fireplace, Home, PermDeviceInformation, Contacts, LocalFireDepartment, Flag, Campaign, Whatshot, Settings, KeyboardArrowDown, FireplaceOutlined, SchoolOutlined, ScreenLockLandscape, MonitorOutlined } from '@mui/icons-material';
import logo from '../assets/img/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function Header(props) 
{
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(false);
    
    const menu = {
        "/": [
            <Home />,
            "דף ראשי",
        ],
        "users": [
            <Group />,
            "ניהול מכשירים",
        ],
        "storage": [
            <Devices />,
            "ניהול מחסן",
        ],
        "staff": [
            <Warehouse />,
            "שיבוץ מבצעי",
        ],
        "gallery": [
            <Fireplace />,
            "סליידר תמונות",
        ],
        "blocked": [
            <PermDeviceInformation />,
            "מכשירים חסומים",
        ],
        "contacts": [
            <Contacts />,
            "ניהול אלפון",
        ],
        "car_reports": [
            <LocalFireDepartment />,
            "ביקורות רכבי כיבוי אש",
        ],
        "vehicles": [
            <FireplaceOutlined />,
            "ניהול רכבי כיבוי אש",
        ],
        "guides": [
            <SchoolOutlined />,
            "ניהול הדרכה",
        ],
        "sign": [
          <SchoolOutlined />,
          "קרא וחתום",
        ],
        "settings": [
            <Settings />,
            "הגדרות",
        ],
        "status": [
            <MonitorOutlined />,
            "הצגת מסך שיבוץ",
        ],
    };

    const adminMenu = {
        "reports": [
            <Flag />,
            "דיווחים",
        ],
        "alerts": [
            <Campaign />,
            "הודעות מערכת",
        ],
        "stations": [
            <Whatshot />,
            "רשימת תחנות"
        ],
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
    <div>
        <Toolbar sx={{justifyContent: "center"}}><img src={logo} alt="Site logo" /></Toolbar>
        <Divider />
        {props.stationId && <>
          <List>
          {Object.keys(menu).map((index, i) => {
              return (
              <ListItem button key={i.toString()} component={Link} to={index}>
              <ListItemIcon>
                  {menu[index][0]}
              </ListItemIcon>
              <ListItemText primary={menu[index][1]} />
              </ListItem>
              );
          })}
          </List>
        </>}
        {props.access === 1 && <React.Fragment>
        <Divider />
        <ListSubheader sx={{color: "gray"}}>מנהל אפליקציה</ListSubheader>
        <List>
            {Object.keys(adminMenu).map((index, i) => (
            <ListItem button key={i.toString()}>
                <ListItemIcon>
                {adminMenu[index][0]}
                </ListItemIcon>
                <ListItemText primary={adminMenu[index][1]} />
            </ListItem>
            ))}
        </List>
        </React.Fragment>}
    </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (<React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#E2122A",
          boxShadow: 'unset',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="לחץ לאפשרויות נוספות">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={props.name[0]} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            &nbsp;&nbsp;
            {props.name}
            {props.access === 1 && 
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="outlined" {...bindTrigger(popupState)} disableElevation sx={{'color': '#fff', borderColor: '#fff', ml: 2}}>
                    {props.stationName}
                    <KeyboardArrowDown />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => { popupState.close(); props.handleStationChange(null); }} sx={{fontWeight: (props.stationId === null ? 'bold' : 'normal')}}>מצב מנהל</MenuItem>
                    {Object.keys(props.stations).map((key) => {
                      return (
                        <MenuItem key={key} onClick={() => { popupState.close(); props.handleStationChange(props.stations[key]['id']); }} sx={{fontWeight: (props.stations[key]['id'] === parseInt(props.stationId) ? 'bold' : 'normal')}}>{props.stations[key]['name']}</MenuItem>
                      )
                    })}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
            >
                <MenuItem>
                  <Typography textAlign="center" component={Link} to="/signout" sx={{color: "black", fontSize: 14, textDecoration: "none"}}>{"יציאה"}</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
  
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </React.Fragment>
    );

}

export default Header;