import React from "react";
import { Add, Delete, SaveOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Divider, FormControl, Grid, IconButton, Paper, TextField, Tooltip } from "@mui/material";
import { useContext } from "react";
import { GuidesContext } from "../sources/contexts/GuidesContext";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Base64 } from 'js-base64';
import { Cookies } from "../functions";
import { Controller } from "react-hook-form";

function uploadAdapter(loader)
{
    const API_URL = 'https://api.firestation.online/admin/upload';
    return {
        upload: () => {
            return new Promise((resolve, reject) => {
                const body = new FormData();
                loader.file.then((file) => {
                    body.append("files", file);
                    body.append("userId", Cookies.get('token'));
                    fetch(`${API_URL}`, {
                        method: "post",
                        body: body
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        resolve({
                            default: `https://api.firestation.online/uploads/${res.filename}`
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
                });
            });
        }
    };
}

function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}

function EditGuide()
{
    const { data, dataLoaded, addNewItem, submitted, handleUpdate, update, handleUpdateSubmit, updateSetValue, deleteItem, handleImage } = useContext(GuidesContext);

    if (!dataLoaded)
        return (<Paper sx={{p: 3, justifyContent: "center", alignSelf: "center", alignItems: "center", display: "flex", flexDirection: "column", lineHeight: 3}}><CircularProgress />טוען..</Paper>);

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Paper sx={{p: 3}}>
                <Grid container spacing={3}>
                    <Grid item xs={1} textAlign={"center"}>
                        <img src={data.imgUrl} alt={data.title} height={'70'} width={"100%"} style={{objectFit: 'contain'}} />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            defaultValue={data.title}
                            control={update}
                            name="title"
                            rules={{required: true}}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <FormControl fullWidth>
                                    <TextField
                                        name={name}
                                        type={'text'}
                                        variant={'standard'}
                                        value={value}
                                        error={!!error}
                                        helperText={error ? error.message : ''}
                                        label={'כותרת המאמר'}
                                        InputLabelProps={{shrink: true}}
                                        onChange={onChange}
                                        sx={{"& input": {fontSize: 30}}}
                                    />
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} alignSelf={'center'}>
                        &nbsp;
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={(e) => handleImage(e.target.files)}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span">
                                בחר תמונה
                            </Button>
                        </label>&nbsp;
                    </Grid>
                </Grid>
                <Divider sx={{mt: 3, mb: 3}} />
                {data.data && data.data.map((item, key) => {
                    const input = `data[${key}][title]`;
                    const content = `data[${key}][data]`;
                    return (
                        <div key={key}>
                            <Paper sx={{p: 3, mb: 2}}>
                                <Grid container sx={{mb: 2}}>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={update}
                                            name={input}
                                            shouldUnregister={true}
                                            defaultValue={item.title}
                                            rules={{required: true}}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                            }) => (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        type={'text'}
                                                        variant={'standard'}
                                                        value={value ?? ''}
                                                        error={!!error}
                                                        helperText={error ? error.message : ''}
                                                        label={'כותרת המקטע'}
                                                        InputLabelProps={{shrink: true}}
                                                        onChange={onChange}
                                                        sx={{"& input": {fontSize: 30}}}
                                                        disabled={submitted}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} textAlign={'end'}>
                                        <Tooltip title={'מחיקת מקטע'} disabled={submitted}><IconButton onClick={() => deleteItem(key)}><Delete /></IconButton></Tooltip>
                                    </Grid>
                                </Grid>
                                <Controller
                                    control={update}
                                    name={content}
                                    defaultValue={Base64.decode(item['data'])}
                                    rules={{required: true}}
                                    shouldUnregister={true}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState,
                                    }) => (
                                        <CKEditor 
                                            disabled={submitted}
                                            editor={ ClassicEditor }
                                            data={value}
                                            config={{
                                                language: 'he',
                                                extraPlugins: [uploadPlugin]
                                            }}
                                            name={name}
                                            ref={ref}
                                            onChange={( event, editor ) => {
                                                updateSetValue(content, editor.getData());
                                            }}
                                        />
                                    )}
                                />
                        </Paper>
                    </div>
                    );
                })}

                <Grid container>
                    <Grid item xs={12} sx={{ textAlign: "center"}}>
                        <Button disableElevation color={'primary'} variant={'contained'} size={'large'} sx={{mr: 1}} onClick={addNewItem} disabled={submitted}><Add /> הוספת מקטע חדש</Button>
                        <Button disableElevation color={'success'} variant={'contained'} size={'large'} disabled={submitted} onClick={handleUpdateSubmit((data) => handleUpdate(data))}>{submitted ? <CircularProgress size={26} sx={{color: 'gray'}} /> : <><SaveOutlined /> שמור מקטעים</>}</Button>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}
export default EditGuide;