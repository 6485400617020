import axios from 'axios';
import React, { Component } from 'react';
import { Cookies, Req, User } from '../../functions';

function createData(id, user_name, sub, info,time,expires,urgent,countUsers, assets ,selectedData) {
    return { id, user_name, sub, info,time ,expires,urgent,countUsers, assets ,selectedData};
}

function UsersData(id,FullName,status) {
    return { id,FullName,status};
}

const SignContext = React.createContext();
class SignProvider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            Users: [],
            dataLoaded: false,
            rowId: null,
            errorMessage: "",
            open: false,
            openStatus: false,
            user_name: "",
            file:[],
            assets:[],
            sub: "",
            time:"",
            info: "",
            expires:"",
            urgent:0,
            selectedData:[],
        };
    }

    handleEdit = (data) => {
        this.setState({ 
            open: true,
            openStatus:false,
            user_name: data.user_name,
            file:data.file,
            time: data.time,
            sub: data.sub,
            info:data.info,
            expires:data.expires,
            urgent:data.urgent,
            rowId: data.id,
            assets:data.assets,
            selectedData:data.selectedData,
            errorMessage: "",
        });
    };

    handleAdd = () => {
        this.setState({
            open: true,
            openStatus:false,
            user_name: "",
            file:[],
            assets:[],
            sub: "",
            time:"",
            info: "",
            expires:"",
            urgent:0,
            selectedData:[],
            rowId: null,
            errorMessage: ""
        });
    };

    handleImage = (e) => {
        this.setState({ file: e.files });
    };

    handleSubmit = async (UsersSend) => {
  
        const postData = new FormData();
        postData.append("files[]", this.state.file);
        postData.append("expires", this.state.expires);
        postData.append("sub", this.state.sub);
        postData.append("info", this.state.info);
        postData.append("Users", UsersSend);
        if (this.state.rowId)
            postData.append("id", this.state.rowId);
        let d = this;
        let data = {
            "files[]": this.state.file,
            "expires": this.state.expires,
            "sub": this.state.sub,
            "info": this.state.info,
            "urgent": this.state.urgent,
            "Users":UsersSend,
        };

        if (this.state.rowId)
            data['id'] = this.state.rowId;

        try {
            
            await axios.post("https://api.firestation.online/admin/tasks/" + (this.state.rowId ? "edit" : "add"), data, {headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': (User.isLogin() ? "Bearer " + Cookies.get('token') : ""),
            }
        }).then(function(res) {
            const success = res.data.success;
            const error = res.data.error;

            if (success) {
                
                d.setState({ errorMessage: "", open: false, dataLoaded: false });
                d.getData();
                return;
            }

            d.setState({ errorMessage: error });
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    closeEdit = () => {
        this.setState({ open: false });
    };

    openStatusDialog = () => {
        this.setState({ openStatus: true });
    };
    closeStatusDialog = () => {
        this.setState({ openStatus: false });
    };
    setData = (key, value) => {
        this.setState({ [key]: value });
    };

    handleDelete = async (id) => {
        let d = this;
        try {
            await Req("admin/tasks/delete", "post", { "id": id }).then(function(data) {
               if (data.success) {
                   d.setState({ dataLoaded: false });
                   d.getData();
               } 
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleDeleteFile = async (id,url) => {
        let d = this;
        try {
            await Req("admin/tasks/deleteFile", "post", { "id": id,"url":url }).then(function(data) {
                
               if (data.success) {
                   d.setState({ dataLoaded: false });
                   d.getData();
               } 
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleSearch = (value) => {
        this.setState({ dataLoaded: false });
        const filteredRows = this.state.rows.filter((row) => {
            return row.sub.toLowerCase().includes(value.toLowerCase()) ||row.time.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({ rows: filteredRows, dataLoaded: true });
        if(!value){this.getData();}
    };

    getData = async () => {
        let loadedData = [];
        let selectedDatas = [];
        let e = this;
        try {
            await Req("admin/tasks", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach((e) => {
                        var dataSigns = JSON.parse(e.data);
                        var select =[];   
                        JSON.parse(e.data).forEach((x) => {   
                              
                            select.push(UsersData(
                                x.id,
                                x.user_name,
                                x.status
                            ));
                        })
                    
                        loadedData.push(createData(
                            e.id,
                            e.user_name,
                            e.sub,
                            e.info,
                            e.time,
                            e.expires,
                            e.urgent,
                            dataSigns.length,
                            JSON.parse(e.assets)[0] ?? [],
                            select
                       
                        ));
                    
                    });
                }
            });
            e.setState({
                // selectedData: selectedDatas,
                rows: loadedData,
                dataLoaded: true
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    getDataUsers = async () => {
        
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/users", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach((e) => {   
                           
                        loadedData.push(UsersData(
                            e.id,
                            e.user_name,
                            "0"
                        ));
                    });
                }
            });
            e.setState({
                Users: loadedData,
                dataLoaded: true
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
        this.getDataUsers();
    }
    
    render() {
        return (
            <SignContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
                handleSearch: this.handleSearch,
                setData: this.setData,
                closeEdit: this.closeEdit,
                openStatusDialog:this.openStatusDialog,
                closeStatusDialog:this.closeStatusDialog,
                handleEdit: this.handleEdit,
                handleDelete: this.handleDelete,
                handleSubmit: this.handleSubmit,
                handleAdd: this.handleAdd,
                handleImage: this.handleImage,
                handleDeleteFile: this.handleDeleteFile
            }}>
                {this.props.children}
            </SignContext.Provider>
        );
    }
}

export { SignContext, SignProvider }