import { Button, Container, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import { Send } from '@mui/icons-material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import '../assets/css/login.css';
import { Req } from '../functions';
import { useNavigate } from 'react-router-dom';

export default function Contact()
{
    let history = useNavigate();
    const send = async (data) => {
        await Req('contact', 'post', {
            "fullname": data.fullname,
            "email": data.email,
            "subject": data.subject,
            "message": data.message
        }).then(function(res) {
            if (!res['success']) {
                return;
            }
            resetForm();

            setTimeout(function() {
                history('https://firestation.online');
            }, 2000);
        });
    }

    const { control: contact, handleSubmit: submit, reset: resetForm } = useForm();
    return (<>
        <Container sx={{textAlign: "center", mt: 5}} maxWidth={'sm'}>
            <h1>יצירת קשר</h1>
            <form style={{marginTop: 20}} onSubmit={submit((data) => send(data))}>
                <FormControl fullWidth sx={{marginBottom: 2}}>
                    <InputLabel shrink>שם מלא</InputLabel>
                    <Controller
                        name="fullname"
                        control={contact}
                        rules={{ required: "שדה זה חובה" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                type="text"
                                onChange={onChange}
                                value={value === undefined ? '' : value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                variant={'standard'}
                                InputLabelProps={{shrink: true}}
                            />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: 2}}>
                    <InputLabel shrink>דוא"ל</InputLabel>
                    <Controller
                        name="email"
                        control={contact}
                        rules={{ required: "שדה זה חובה", pattern: { message: "כתובת המייל שהוזנה אינה תקינה", value: /\S+@\S+\.\S+/ }}}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                type="email"
                                onChange={onChange}
                                value={value === undefined ? '' : value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                variant={'standard'}
                                InputLabelProps={{shrink: true}}
                            />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: 2}}>
                    <InputLabel shrink>נושא</InputLabel>
                    <Controller
                        name="subject"
                        control={contact}
                        rules={{ required: "שדה זה חובה" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                type="text"
                                onChange={onChange}
                                value={value === undefined ? '' : value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                variant={'standard'}
                                InputLabelProps={{shrink: true}}
                            />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth sx={{marginBottom: 2}}>
                    <InputLabel shrink>תוכן ההודעה</InputLabel>
                    <Controller
                        name="message"
                        control={contact}
                        rules={{ required: "שדה זה חובה" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                type="text"
                                onChange={onChange}
                                value={value === undefined ? '' : value}
                                error={!!error}
                                multiline
                                minRows={10}
                                helperText={error ? error.message : null}
                                variant={'standard'}
                                InputLabelProps={{shrink: true}}
                            />
                        )}
                    />
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button type="submit" fullWidth variant={'contained'} disableElevation color={'success'}>
                            <Send />
                            &nbsp;
                            שלח
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => window.location.href='/'} fullWidth variant={'outlined'} color="error">חזור להתחברות</Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    </>);
}