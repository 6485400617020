import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Req } from '../../functions';

const UsersContext = React.createContext();
const UsersProvider = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rows, setRows] = useState([]);
    const [userId, setUserId] = useState(null);
    const [army, setIsArmy] = useState(false);
    const { control: user, setValue, handleSubmit, reset } = useForm({ mode: "onChange" });

    const loadUserData = (data) => {
        if (data.id) {
            setUserId(data.id);
            setValue("user_name", data.user_name);
            setValue("priority", data.priority);
            setValue("email", data.email);
            let phone = data.phone.replace("-", "");
            setValue("phone", phone);
            setValue("shift", data.shift);
            setValue("distribution", JSON.parse(data.distribution));
            return;
        }

        setUserId(null);
        reset()
    };

    const SaveAndUpdate = async (dialogState, data) => {
        if (userId !== null)
            data['userid'] = parseInt(userId);
        try {
            await Req("admin/users/" + (data['userid'] ? "edit" : "add"), "post", data).then((data) => {
                if (!data.success)
                    return;

                setUserId(null)
                reset()
                dialogState(false)
                getData()
            });
        }
        catch (error) {
            console.log(error);
        }
        
    };
    
    const handleDelete = async (id) => {
        try {
            await Req("admin/users/delete", "post", { "userid": id }).then(function(data) {
               if (!data.success)
                return;
                getData();
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (value) => {
        if (!value)
        {
            getData();
            return;
        }
        getData().then(() => {
            const filteredRows = rows.filter((row) => {
                return row.user_name.toLowerCase().includes(value.toLowerCase()) || row.email.toLowerCase().includes(value.toLowerCase()) || row.phone.toLowerCase().includes(value.toLowerCase());
            });
            setRows(filteredRows);
        });
    };

    const getData = async () => {
        setDataLoaded(false);
        try {
            await Req("admin/users", "get").then(function(data) {
                if (data.success) {
                    setRows(data.data);
                    setIsArmy(data.isArmy);
                    setDataLoaded(true);
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      getData();
    }, []);
    
    return (
        <UsersContext.Provider value={{
            dataLoaded,
            rows,
            handleDelete,
            handleSearch,
            loadUserData,
            user,
            setValue,
            handleSubmit,
            army,
            userId,
            SaveAndUpdate
        }}>
            {props.children}
        </UsersContext.Provider>
    );
}

export { UsersContext, UsersProvider }