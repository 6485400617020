import React, { Component } from 'react';
import { Req } from '../../functions';

function createData(id, vehicle, type, date, reviewedBy, items) {
    return { id, vehicle, type, date, reviewedBy, items };
}

const CarReportsContext = React.createContext();
class CarReportsProvider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            rows: [],
            open: false,
            data: null,
        };
    }

    handleView = (row) => {
        if (!row) {
            this.setState({
                open: false,
                data: [],
            });
            return;
        }
        this.setState({
            open: true,
            data: row,
        });
    };

    setData = (key, value) => {
        this.setState({ [key]: value });
    };

    getData = async () => {
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/car_reports/history", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach(e => {
                        loadedData.push(createData(
                            e.id,
                            e.vehicle['vehicle'],
                            (e.type === 'tools' ? "ביקורת ציוד" : "ביקורת נהגים"),
                            e.date,
                            e.user['first_name'] + ' ' + e.user['last_name'],
                            e.items,
                        ));
                    });
                    e.setState({
                        dataLoaded: true,
                        rows: loadedData
                    });
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
    }
    
    render() {
        return (
            <CarReportsContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
                handleView: this.handleView
            }}>
                {this.props.children}
            </CarReportsContext.Provider>
        );
    }
}

export { CarReportsContext, CarReportsProvider }