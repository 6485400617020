import React from 'react';
import { TextField } from '@mui/material';

function SearchBar(props)
{
    return (
        <>
        <TextField placeholder={'סנן נתונים..'} onChange={(e) => props.handleChange(e.currentTarget.value)} />
        <br />
        <br />
        </>
    );
}

export default SearchBar;