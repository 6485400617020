import React, { useContext, useState } from 'react';
import { Paper, TextField, Grid, Divider, Button, Container, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Box, FormControl, DialogContentText, Switch, Select, MenuItem, InputLabel, Alert, CircularProgress, } from '@mui/material';
import FsLightbox from 'fslightbox-react';
import { Add, DeleteForeverOutlined } from '@mui/icons-material';
import { DataGrid, GridToolbarQuickFilter, heIL } from '@mui/x-data-grid';
import { Controller, useForm } from 'react-hook-form';
import VehicleSpec from './VehicleSpec';
import axios from 'axios';
import { Cookies, User } from '../functions';
import { VehiclesContext } from '../sources/contexts/VehiclesContext';
import { useParams } from 'react-router-dom';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: "rgba(173,216,230,.2)",
        borderBottom: ".5px solid #dfdfdf",
        borderTopRightRadius: "3px",
        borderTopLeftRadius: "3px",
      }}
    >
      <GridToolbarQuickFilter placeholder={'סנן ע"פ הערכים בטבלה..'} />
    </Box>
  );
}

function EditVehicle(props)
{
    let { id } = useParams();
    const { rows, processRowUpdate, processRowInsert, processRowDelete, setAdd, add, getData, dataLoaded } = useContext(VehiclesContext);
    const [toggler, setToggler] = useState(false);
    const [open, setOpen] = useState(false);
    const [isDriver, setDriver] = useState(false);
    const { control: item, handleSubmit: createItem, watch, reset } = useForm({
        mode: "onChange"
    });

    const handleOpen = (isDriver) => {
        reset()
        setDriver(isDriver);
        setAdd(!add);
    };

    const columns = [
        { field: 'priority', headerName: 'חשיבות', flex: 1, renderCell: (params) => {
            if (!params.row.isParent)
                return <span style={{color: (parseInt(params.value) === 1 ? 'RED' : 'GREEN')}}> {parseInt(params.value) === 1 ? 'גבוהה' : 'נמוכה'}</span>
        }, editable: true, },
        { field: 'name', headerName: 'כותרת', flex: 1, editable: true, sortable: false, renderCell: (params) => (params.row.isParent ? <strong>{params.value}</strong> : params.value) },
        { field: 'description', headerName: 'תיאור', flex: 1, editable: true, sortable: false, },
        { field: 'value', headerName: 'כמות', flex: 1, editable: true, sortable: false, },
        { field: 'order_by', headerName: 'סדר לפי', flex: 1, editable: true, type: 'number', sortable: false, },
        { field: 'delete', headerName: 'אפשרויות', flex: 1, renderCell: (params) => <IconButton color={'error'} onClick={() => processRowDelete(params.id, id)}><DeleteForeverOutlined /></IconButton>, sortable: false, },
    ];

    const driver_columns = [
        { field: 'name', headerName: 'כותרת', flex: 1, editable: true, sortable: false, renderCell: (params) => (params.row.isParent ? <strong>{params.value}</strong> : params.value) },
        { field: 'description', headerName: 'תיאור', flex: 1, editable: true, sortable: false, },
        { field: 'order_by', headerName: 'סדר לפי', flex: 1, editable: true, type: 'number', sortable: false, },
        { field: 'delete', headerName: 'אפשרויות', flex: 1, renderCell: (params) => <IconButton color={'error'} onClick={() => processRowDelete(params.id, id, true)}><DeleteForeverOutlined /></IconButton>, sortable: false, },
    ];

    const handleImage = async (files) => {
        const postData = new FormData();
        postData.append("image", files[0]);
        postData.append("vehicle", rows['id']);
 
        try {
            await axios.post("https://api.firestation.online/admin/vehicles/upload", postData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': (User.isLogin ? "Bearer " + Cookies.get('token') : ""),
                },
            }).then((res) => {
                const success = res.data.success;
                if (!success) {
                    return;
                }
                getData(id);
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    if (!dataLoaded)
        return (<Paper sx={{p: 3, justifyContent: "center", alignSelf: "center", alignItems: "center", display: "flex", flexDirection: "column", lineHeight: 3}}><CircularProgress />טוען..</Paper>);

    return (
        <Paper sx={{ width: '100%'}}>
            <Dialog open={add} onClose={() => setAdd(false)} maxWidth={'md'}>
                <DialogTitle>הוספת פריט חדש</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{mb: 2}} component={'div'}>
                        <Alert severity='info'>הציוד יתווסף באופן אוטומטי ללא צורך ברענון.<br /> במידה והציוד יוגדר בעדיפות גבוהה כאשר תתבצע ביקורת והציוד יסומן כלא תקין- תקבלו הודעת התרעה.</Alert>
                    </DialogContentText>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Grid container sx={{mb: 2}}>
                            <Grid item xs={6}>
                                <Controller
                                    defaultValue={false}
                                    control={item}
                                    name="type"
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState,
                                    }) => (
                                        <>
                                        <Switch
                                            name={name}
                                            onBlur={onBlur} // notify when input is touched
                                            onChange={onChange} // send value to hook form
                                            checked={value}
                                            inputRef={ref}
                                            color={'info'}
                                        />
                                        {value === true ? "פריט אב" : "פריט רגיל"}
                                        </>
                                    )}
                                />
                            </Grid>
                            { (!watch("type") && !isDriver) && <Grid item xs={6}>
                                <Controller
                                    defaultValue={false}
                                    control={item}
                                    name="priority"
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, isTouched, isDirty, error },
                                        formState,
                                    }) => (
                                        <>
                                        <Switch
                                            onBlur={onBlur} // notify when input is touched
                                            onChange={onChange} // send value to hook form
                                            checked={value}
                                            inputRef={ref}
                                            color={'error'}
                                        />
                                        {value === true ? "עדיפות גבוהה" : "עדיפות נמוכה"}
                                        </>
                                    )}
                                />
                            </Grid>}
                        </Grid>
                        {!watch("type") &&
                        <Controller
                            control={item}
                            name="parent"
                            rules={{required: true}}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <FormControl fullWidth sx={{mb: 2}}>
                                    <InputLabel shrink>פריט אב</InputLabel>
                                    {!isDriver ? 
                                        <Select name={name} variant={'standard'} error={!!error} fullWidth defaultValue={''} label={'פריט אב'} onChange={onChange} value={value ?? ''}>
                                            {rows['parts'] && Object.values(rows['parts']).filter((a) => a.isParent === true).sort((a, b) => a.name.localeCompare(b.name)).map((item, i) => {
                                                return (<MenuItem key={i} value={item['id']}>{item['name']}</MenuItem>);
                                            })}
                                        </Select>
                                    : 
                                        <Select name={name} variant={'standard'} error={!!error} fullWidth defaultValue={''} label={'פריט אב'} onChange={onChange} value={value ?? ''}>
                                            {rows['driver_parts'] && Object.values(rows['driver_parts']).filter((a) => a.isParent === true).sort((a, b) => a.name.localeCompare(b.name)).map((item, i) => {
                                                return (<MenuItem key={i} value={item['id']}>{item['name']}</MenuItem>);
                                            })}
                                        </Select>
                                    }
                                </FormControl>
                            )}
                        />}
                        <Controller
                            control={item}
                            name="name"
                            rules={{required: true}}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <FormControl fullWidth sx={{mb: 2}}>
                                    <TextField name={name} variant={'standard'} error={!!error} label={'שם הפריט'} InputLabelProps={{shrink: true}} onChange={onChange} value={value ?? ''} />
                                </FormControl>
                            )}
                        />
                        {!watch("type") && <>
                            <Controller
                                control={item}
                                name="description"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) => (
                                    <FormControl fullWidth sx={{mb: 2}}>
                                        <TextField type={'text'} variant={'standard'} error={!!error} label={'תיאור'} InputLabelProps={{shrink: true}} multiline rows={2} onChange={onChange} value={value ?? ''}/>
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={item}
                                name="quantity"
                                rules={{
                                    required: true,
                                    pattern:{
                                        value: /^(0|[1-9]\d*)(\.\d+)?$/
                                    },
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                    fieldState: { invalid, isTouched, isDirty, error },
                                    formState,
                                }) => (
                                    <FormControl fullWidth sx={{mb: 2}}>
                                        <TextField name={name} variant={'standard'} label={'כמות'} InputLabelProps={{shrink: true}} onChange={onChange} value={value ?? ''} error={!!error} />
                                    </FormControl>
                                )}
                            />
                        </>}
                        <DialogActions sx={{p: 0}}>
                            <Button color={'success'} type={'submit'} onClick={createItem((data) => processRowInsert(data, isDriver))}>הוסף</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <VehicleSpec data={rows['data']} vehicle={rows['vehicle']} vehicleId={id} setOpen={setOpen} seats={rows['seats']} getData={getData} />
            </Dialog>
            <Container sx={{padding: 3}}>
                <h1>{rows['vehicle']}</h1>
                <Button variant={'contained'} onClick={() => setOpen(true)} color={'success'}>ערוך מאפייני רכב</Button>
                &nbsp;
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleImage(e.target.files)}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                        בחר תמונה
                    </Button>
                </label>&nbsp;
                <Button variant="outlined" onClick={() => setToggler(!toggler)}>הצג תמונה נוכחית</Button>
                <FsLightbox
                    toggler={toggler}
                    type="image" 
                    sources={[
                        rows['image_url']
                    ]}
                    // onClose={() => setToggler(!toggler)}
                /> 
            </Container>
            <Divider />

            <Container sx={{p: 3}}>
                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={6} alignSelf={'center'}>
                        <h3>ביקורת ציוד</h3>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                        <IconButton color={'success'} onClick={() => handleOpen(false)}><Add /></IconButton>
                    </Grid>
                </Grid>
                <DataGrid
                    columns={columns}
                    rows={rows['parts'] ?? []}
                    sx={{
                        height: 350,
                        "&.MuiDataGrid-cell--textRight": {
                            justifyContent: "flex-start",
                        },
                    }}
                    components={{ Toolbar: QuickSearchToolbar }}
                    localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                    experimentalFeatures={{ newEditingApi: true }}
                    processRowUpdate={processRowUpdate}
                />
            </Container>
            <Divider />
            <Container sx={{p: 3}}>
                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={6} alignSelf={'center'}>
                        <h3>ביקורת נהגים</h3>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                        <IconButton color={'success'} onClick={() => handleOpen(true)}><Add /></IconButton>
                    </Grid>
                </Grid>
                <DataGrid
                    columns={driver_columns}
                    rows={rows['driver_parts'] ?? []}
                    sx={{
                        height: 350,
                        "&.MuiDataGrid-cell--textRight": {
                            justifyContent: "flex-start",
                        },
                    }}
                    components={{ Toolbar: QuickSearchToolbar }}
                    localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                    experimentalFeatures={{ newEditingApi: true }}
                    processRowUpdate={processRowUpdate}
                />
            </Container>
        </Paper>
    );
}
export default EditVehicle;