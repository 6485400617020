import React, { Component } from 'react';
import { Req } from '../../functions';

function createData(id, name, itemInfo, category, location, manufacturer, model, dateCheck, dateNextCheck, amount, amountMin, status, note, time, nameChange, options) {
    return { id, name, itemInfo, category, location, manufacturer, model, dateCheck, dateNextCheck, amount, amountMin, status, note, time, nameChange, options };
}


const StorageContext = React.createContext();
class StorageProvider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            rows: []
        };
    }

    getData = async () => {
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/storage", "get").then(function(data) {
                if (data.success) {
                    Object.keys(data.data).forEach((e) => {
                        loadedData.push(createData(
                            data.data[e].id,
                            data.data[e].name,
                            data.data[e].itemInfo,
                            data.data[e].category,
                            data.data[e].location,
                            data.data[e].manufacturer,
                            data.data[e].model,
                            data.data[e].dateCheck,
                            data.data[e].dateNextCheck,
                            data.data[e].amount,
                            data.data[e].amountMin,
                            data.data[e].status,
                            data.data[e].note,
                            data.data[e].time,
                            data.data[e].nameChange
                        ));
                    });
                    // data.data.forEach((e) => {
                    //     console.log(e);
                    //     loadedData.push(createData(
                    //         e.first_name + ' ' + e.last_name, 
                    //         e.user_name,
                    //         e.email,
                    //         e.phone,
                    //         e.priority,
                    //         e.ip_user,
                    //         e.id
                    //     ));
                    // });
                }
            });
            e.setState({
                rows: loadedData,
                dataLoaded: true
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // useEffect(() => {
    //     this.state = {};
    //     document.title = 'התחנה - ניהול משתמשים';
    //     const getData = async () => {
    //      let result = await Req("admin/users", "get").then(function(data) {
    //         if (data.success) data.data.forEach((e) => {
    //             console.log(e);
    //             rows.push(createData(e.first_name, 'IN', 1324171354, 3287263));
    //         });
    //         console.log(rows);
    //      });
    //     }
    //     getData();

    // }, []);

    // return (
    //     <UsersContext.Provider
    //         value={{
    //             createData,
    //             rows,
    //         }}
    //     >
    //         {props.children}
    //     </UsersContext.Provider>
    // )

    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
    }
    
    render() {
        return (
            <StorageContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
            }}>
                {this.props.children}
            </StorageContext.Provider>
        );
    }
}

export { StorageContext, StorageProvider }