import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Grid, Fab, Tooltip, TextField, DialogActions, Dialog, DialogTitle, Alert, DialogContent, } from '@mui/material';
import { ContactsContext } from './contexts/ContactsContext';
import SearchBar from '../components/SearchBar';
import AddIcon from '@mui/icons-material/Add';

const columns = [
  { id: 'name', label: 'שם', minWidth: 170 },
  { id: 'role', label: 'תפקיד', minWidth: 170 },
  { id: 'phone', label: 'טלפון', minWidth: 220 },
  { id: 'options', label: 'אפשרויות' }
];

function Contacts() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { rows, dataLoaded, handleSearch, handleDelete, open, errorMessage, setData, handleSubmit, handleEdit, handleAdd, closeEdit, rowId, name, role, phone } = useContext(ContactsContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

  return (
      <>
        <Dialog open={open} onClose={() => closeEdit()}>
            <DialogTitle sx={{paddingBottom: 0}}>{rowId ? "עריכת איש קשר" : "הוספת איש קשר חדש"}</DialogTitle>
            <DialogContent>
                {errorMessage && <Alert severity={"error"} sx={{marginTop: 1}}>{errorMessage}</Alert>}
                <TextField
                    margin="dense"
                    id="name"
                    label="שם מלא"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setData("name", e.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="role"
                    label="תפקיד"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={role}
                    onChange={(e) => setData("role", e.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="טלפון נייד"
                    type="tel"
                    fullWidth
                    variant="standard"
                    value={phone}
                    onChange={(e) => setData("phone", e.currentTarget.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeEdit()} sx={{"color": "gray", "fontWeight": "normal"}}>ביטול</Button>
                <Button onClick={() => handleSubmit()} color={"success"}>{rowId ? "עדכן" : "שמור"}</Button>
            </DialogActions>
        </Dialog>
        <Grid container>
            <Grid item xs={6}>
                <SearchBar handleChange={(data) => handleSearch(data)} />
            </Grid>
            <Grid item xs={6} sx={{textAlign: "end"}}>
                <Tooltip title={"הוספת איש קשר חדש"}>
                    <Fab color="success" aria-label="add" size='large' onClick={() => handleAdd()}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
        <Paper sx={{ width: '100%' }}>
        <TableContainer>
            <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; ניהול אלפון</TableCell>
                    </TableRow>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.label}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, o) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={o} align={column.align}>
                                {column.id === 'options' ? <>
                                    <Button variant={"outlined"} color={"primary"}  onClick={() => handleEdit(row)} sx={{marginRight: 1}}>עריכה</Button>
                                    <Button variant={"outlined"} color={"error"}  onClick={() => handleDelete(row.id)}>מחיקה</Button>
                                </> : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"רשומות לעמוד: "} 
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            key={"search"}
        />
        </Paper>
    </>
  );
}

export default Contacts;