import React, { useContext , useState,useEffect} from 'react'; 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, CircularProgress, Grid, Fab,DialogContentText, Tooltip, TextField, DialogActions, Dialog, DialogTitle, Alert, DialogContent,IconButton } from '@mui/material';
import { SignContext } from './contexts/SignContext';
import SearchBar from '../components/SearchBar';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Add, Delete, Edit } from '@mui/icons-material';
import { MultiSelect } from "react-multi-select-component";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [];


const columns = [
  { id: 'time', label: 'תאריך', minWidth: 170 },
  { id: 'sub', label: 'מטלה', minWidth: 220 },
  { id: 'user_name', label: "יוזם", minWidth: 150 },
  { id: 'countUsers', label: 'נמענים', minWidth: 50 },
  { id: 'expires', label: 'תפוגה', minWidth: 50 },
  { id: 'options', label: 'אפשרויות' }
];

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }



  
  function DeleteConfirmationDialog({ open, onClose, onConfirm }) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>אישור מחיקה</DialogTitle>
        <DialogContent>
          <DialogContentText>
            האם ברצונך למחוק מטלה זו?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            ביטול
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            מחק
          </Button>
        </DialogActions>
      </Dialog>
    );
  }



function Sign() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { rows,Users, dataLoaded, handleSearch, handleDeleteFile,handleDelete,handleImage, open,openStatus, errorMessage, setData, handleSubmit, handleEdit, handleAdd, closeEdit,openStatusDialog,closeStatusDialog, rowId, name, sub,time,info,urgent,expires,assets,selectedData } = useContext(SignContext);
    const [age, setAge] = React.useState('');
    const [value, setUrgent] = React.useState('');
    const [selected, setSelected] = useState([]);
    const [dataSign, setDataSign] = useState([]);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteDialogOpenId, setDeleteDialogOpenId] = useState('');


    const options = [];
    const [lessonImage, setLessonImage] = React.useState([]);

    const handleLessonImage = (event) => {
        handleImage(event.target);
        const data = [];
        for (let i = 0; i < event.target.files.length; i++) {
        data.push(event.target.files[i]);
        }
        setLessonImage((old)=> [...old,...data]);
    };
    
    const handleDeleteConfirm = () => {
        // Perform delete action here
        // Once deleted, close the dialog
        handleDelete(deleteDialogOpenId);
        setDeleteDialogOpen(false);
      };

      const functionDelete = (xId) => {
        // Perform delete action here
        // Once deleted, close the dialog
        setDeleteDialogOpen(true);
        setDeleteDialogOpenId(xId);
        
      };
  


useEffect(() => {
    
    if (selectedData) {
        const newSelected = [];
        selectedData.forEach((name) => {
            const isDuplicate = newSelected.some(item => item.label === name.FullName && item.value === Number(name.id));
            if (!isDuplicate) {
                newSelected.push({
                    label: name.FullName,
                    value: Number(name.id),
                    status: name.status
                });
            }
        });
        setSelected(newSelected);
        setDataSign(newSelected);
    }
}, [selectedData]);


      Users.map((name) => (
        options.push({
            label: name.FullName,
                value: name.id,
                status: name.status})
      ));

 

    const handleChangePerson = (event: SelectChangeEvent<typeof personName>) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
   

    const handleChangeAge = (event: SelectChangeEvent) => {
      setAge(event.target.value);
    };
    const handleChange = (event) => {
        setUrgent(event.target.value);
        setData("urgent", event.target.value);
      };

  return (
      <>
        <Dialog open={open} onClose={() => closeEdit()}>
            <DialogTitle sx={{paddingBottom: 0}}>{rowId ? "עריכת משימה" : "הוספת איש קשר חדש"}</DialogTitle>
            <DialogContent>
                {errorMessage && <Alert severity={"error"} sx={{marginTop: 1}}>{errorMessage}</Alert>}
                {rowId &&<TextField
                    disabled
                    margin="dense"
                    id="time"
                    label="תאריך"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={time}
                
                />}
                
                <TextField
                    margin="dense"
                    id="sub"
                    label="כותרת"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={sub}
                    onChange={(e) => setData("sub", e.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    id="info"
                    label="תיאור המטלה"
                    type="text"
                    maxRows={4}
                    fullWidth
                    variant="standard"
                    value={info}
                    onChange={(e) => setData("info", e.currentTarget.value)}
                    multiline
                />
                <TextField
                    margin="dense"
                    id="expires"
                    label="תפוגה"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={expires}
                    onChange={(e) => setData("expires", e.currentTarget.value)}
                />
                <div>
                <FormControl sx={{ marginTop: 2, marginBottom: 2 }}fullWidth>
                <InputLabel id="priority-label" sx={{ top: -2 }}>דחיפות</InputLabel>
                <Select
                    labelId="priority-label"
                    id="priority-select"
                    value={urgent}
                    onChange={handleChange}
                    label="דחיפות"
                >
                    <MenuItem value={1}>כן</MenuItem>
                    <MenuItem value={0}>לא</MenuItem>
                </Select>
                </FormControl>
                </div>  
                <div>
                <h4>בחירת משתמשים</h4>
                {/* <pre>{JSON.stringify(selected)}</pre> */}
                <MultiSelect
                    options={options}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="FullName"
                />
                </div> 
                
                
                {/* <FormControl style={{ paddingTop: '5px' }}  fullWidth >
                <InputLabel id="demo-simple-select-label">דחיפות</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={urgent}
                    label="דחיפות"
                    onChange={handleChange}
                >
                    <MenuItem value={1}>כן</MenuItem>
                    <MenuItem value={2}>לא</MenuItem>
                </Select>
                </FormControl> */}
                            
                
                {assets.pdf && assets.pdf.map((asset) => (
                <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span><a href={asset.url}>{asset.name}</a></span>
                <Tooltip title={"מחק"}>
                    <IconButton onClick={() => handleDeleteFile(rowId,asset.url)}><Delete /></IconButton>
                </Tooltip>
                </div>
                </>
                ))}
                {assets.image && assets.image.map((asset) => (
                <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span><a href={asset.url}>{asset.name}</a></span>
                <Tooltip title={"מחק"}>
                <IconButton onClick={() => handleDeleteFile(rowId,asset.url)}><Delete /></IconButton>
                </Tooltip>
                </div>
                </>
                ))}
                 
                {lessonImage.map((item, index) => {
                    return (
                    <div key={index}>
                        <span>{item.name}</span>
                    </div>
                    );
                })}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <input type="file" id="files[]" style={{display:"none"}} onChange={(e) => handleLessonImage(e)} multiple
                        accept='application/pdf, image/*'/>
                        <label htmlFor="files[]" className="uploadButton">העלאת קבצים</label>
                    </div>
                    {rowId && <div>
                        <input type="button" id="openDialog" style={{display:"none"}} onClick={() => openStatusDialog()} />
                        <label htmlFor="openDialog" className="uploadButton">סטטוס ביצוע</label>
                    </div>}
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeEdit()} sx={{"color": "gray", "fontWeight": "normal"}}>ביטול</Button>
                <Button onClick={() => handleSubmit(JSON.stringify(selected))} color={"success"}>{rowId ? "עדכן" : "שמור"}</Button>
            </DialogActions>
        </Dialog>
        <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
        <Dialog open={openStatus} onClose={() => closeStatusDialog()}>
            <DialogTitle sx={{paddingBottom: 0}}>{"סטטוס ביצוע מטלה"}</DialogTitle>
            <DialogContent>
            {dataSign && dataSign.map((name) => (
                <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{name.label}</span> {/* הערך של label */}
                    {name.status === 1 ? ( // בדיקה אם ה־status הוא 1
                        <span style={{ color: 'green' }}>בוצע</span> // אם כן, מציג "בוצע" בצבע ירוק
                    ) : (
                        <span style={{ color: 'red' }}>לא בוצע</span> // אם לא, מציג "לא בוצע" בצבע אדום
                    )}
                </div>
                </>
                ))}
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeStatusDialog()} sx={{"color": "gray", "fontWeight": "normal"}}>סגור</Button>
            </DialogActions>
        </Dialog>
        <Grid container>
            <Grid item xs={6}>
                <SearchBar handleChange={(data) => handleSearch(data)} />
            </Grid>
            <Grid item xs={6} sx={{textAlign: "end"}}>
                <Tooltip title={"הוספת מטלה חדשה"}>
                    <Fab color="success" aria-label="add" size='large' onClick={() => handleAdd()}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
        <Paper sx={{ width: '100%' }}>
        <TableContainer>
            <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; ניהול קרא וחתום</TableCell>
                    </TableRow>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.label}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, o) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={o} align={column.align}>
                                {column.id === 'options' ? <>
                                    <Button variant={"outlined"} color={"primary"}  onClick={() => handleEdit(row)} sx={{marginRight: 1}}>עריכה</Button>
                                    <Button variant={"outlined"} color={"error"}  onClick={() => functionDelete(row.id)}>מחיקה</Button>
                                </> : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"רשומות לעמוד: "} 
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            key={"search"}
        />
        </Paper>
    </>
  );
}

export default Sign;