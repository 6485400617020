import React, { Component } from 'react';
import { Req } from '../../functions';

function createData(id, imei, id_device, date, ip_user) {
    return { id, imei, id_device, date, ip_user };
}

const BlockedContext = React.createContext();
class BlockedProvider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            dataLoaded: false,
            rowId: null,
            errorMessage: "",
        };
    }

    setData = (key, value) => {
        this.setState({ [key]: value });
    };

    handleDelete = async (id) => {
        let d = this;
        try {
            await Req("admin/blocked/delete", "post", { "id": id }).then(function(data) {
               if (data.success) {
                   d.setState({ dataLoaded: false });
                   d.getData();
               } 
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleSearch = (value) => {
        this.setState({ dataLoaded: false });
        const filteredRows = this.state.defaultRows.filter((row) => {
            return row.name.toLowerCase().includes(value.toLowerCase()) || row.userName.toLowerCase().includes(value.toLowerCase()) || row.email.toLowerCase().includes(value.toLowerCase()) || row.phone.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({ rows: filteredRows, dataLoaded: true });
    };

    getData = async () => {
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/blocked", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach((e) => {
                        loadedData.push(createData(
                            e.id,
                            e.imei,
                            e.id_device,
                            e.date,
                            e.ip_user
                        ));
                    });
                }
            });
            e.setState({
                rows: loadedData,
                dataLoaded: true
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
    }
    
    render() {
        return (
            <BlockedContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
                handleSearch: this.handleSearch,
                setData: this.setData,
                handleDelete: this.handleDelete
            }}>
                {this.props.children}
            </BlockedContext.Provider>
        );
    }
}

export { BlockedContext, BlockedProvider }