import React, {useContext} from 'react';
import { Card, CardContent, CardHeader, Grid, CircularProgress, IconButton, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PageRouteContext } from './contexts/RouteContext';
import { CheckCircleOutline, Security, TaskAlt } from '@mui/icons-material';

function Dashboard() {
    const context = useContext(PageRouteContext);
    const { logs, dataLoaded } = context;

    return (
    <React.Fragment>
        <Grid container spacing={3}>
            <Grid item lg={12} xl={4}>
                <Card>
                    <CardHeader title="פעולות אחרונות" avatar={<Security sx={{"color": "#bdbdbd"}} />} />
                    <CardContent sx={{padding: 0, borderTop: "3px solid #e2122a"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontSize: 16}}>משתמש</TableCell>
                                        <TableCell sx={{fontSize: 16}}>תחנה</TableCell>
                                        <TableCell sx={{fontSize: 16}}>פעולה</TableCell>
                                        <TableCell sx={{fontSize: 16}}>IP</TableCell>
                                        <TableCell sx={{fontSize: 16}}>תאריך</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!dataLoaded ? <TableRow><TableCell colSpan={5} align={"center"}>
                                        <CircularProgress color={"error"} />
                                    </TableCell></TableRow> : logs.map((item, i) => {
                                        return (
                                        <TableRow key={i}>
                                            <TableCell>{item.userid}</TableCell>
                                            <TableCell>{item.station}</TableCell>
                                            <TableCell>{item.type}</TableCell>
                                            <TableCell>{item.ip}</TableCell>
                                            <TableCell>{item.date}</TableCell>
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={12} xl={4}>
                <Card>
                    <CardHeader title="משימות לטיפול" avatar={<TaskAlt sx={{"color": "#bdbdbd"}} />} />
                    <CardContent sx={{padding: 0, borderTop: "3px solid #e2122a"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontSize: 16}}>הערה</TableCell>
                                        <TableCell sx={{fontSize: 16}}>אפשרויות</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!dataLoaded ? <TableRow><TableCell colSpan={5} align={"center"}>
                                        <CircularProgress color={"error"} />
                                    </TableCell></TableRow> : logs.map((item, i) => {
                                        return (
                                        <TableRow key={i}>
                                            <TableCell>מנ"פ מק"ט <strong>39910</strong> פג תוקף בעוד <strong>29</strong> יום</TableCell>
                                            <TableCell>
                                                <IconButton sx={{margin: 0}} color={"success"}>
                                                    <Tooltip title={"סמן כ'בוצע'"}>
                                                        <CheckCircleOutline />
                                                    </Tooltip>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </React.Fragment>
    );
}
export default Dashboard;