import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// RTL
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { PageRouteContextProvider } from './sources/contexts/RouteContext';

const theme = createTheme({
  direction: 'rtl',
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

// Create rtl cache
const cacheRtl = createCache({
  key: 'makeapp',
  stylisPlugins: [rtlPlugin],
});

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

ReactDOM.render(
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CacheProvider value={cacheRtl}>
          <PageRouteContextProvider>
            <App />
          </PageRouteContextProvider>
        </CacheProvider>
      </ThemeProvider>
    </StylesProvider>,
  document.getElementById('app-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
