import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, CardHeader, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { StationContext } from './contexts/StationContext';
import logo from '../assets/img/logo_white.svg';
import '../assets/css/login.css';

function StationInfo() 
{
    document.getElementsByTagName('body')[0].className='statusPage';
    const { dataLoaded, error, data, settings, dateState } = useContext(StationContext);
    const props = {
      dangerouslySetInnerHTML: { __html: '<br/>' },
    };
    return (
        !dataLoaded ? <>
                <Grid container height={'100vh'} spacing={2} sx={{mt: 0}}>
                    <Grid item xs={12} lg={8} xl={10} justifyContent={'center'} alignSelf={'center'}>
                        <header>
                            {settings['logo'] === '' ? <h2>{settings['title']}</h2> : <img src={settings['logo']} id="logo" alt={settings['title']} />}
                            <time>
                                <strong>יום חמישי</strong> | {dateState.toLocaleString('he-IL', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: false,
                                })}
                            </time>
                        </header>
                        <Grid container spacing={2} sx={{pr: 15, pl: 15,}}>
                            {Object.keys(data).map((item) => {
                                return (
                                    <Grid item xs={12} lg={4} key={item}>
                                        <Card className={'vehicle'}>
                                            <CardHeader title={data[item].vehicle} />
                                            <img src={data[item].image_url} alt={data[item].name} height={80} />
                                            <CardContent>
                                                נהג: {data[item].driver}<br />
                                                {data[item].leader !== '' && <>מפקד צוות: {data[item].leader}<br /></>}
                                                {data[item].fire1 !== '' && <>לוחם אש: {data[item].fire1}<br /></>}
                                                {data[item].fire2 !== '' && <>לוחם אש: {data[item].fire2}</>}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} xl={2} className={'sidebar'}>
                        <h4>חדשות ועדכונים</h4>
                        <div><marquee direction="up" height="95%" scrollamount={3} dangerouslySetInnerHTML={{__html: settings.info}} /></div>

                        <h4>תורנות יומן</h4>
                        <div><p dangerouslySetInnerHTML={{__html: settings.shift}} /></div>
                    </Grid>
                </Grid>
        </> : <>
            <Paper component="div" sx={{ whiteSpace: 'nowrap', backgroundColor: "#f8f8f8", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {error ? <CloseIcon size="large" sx={{fontSize: 42, color: "gray"}} /> : <CircularProgress color={"error"} />}
                <Typography component={'p'} sx={{p: 1}}>
                    {error ? error : "טוען נתונים על התחנה.."}
                </Typography>
            </Paper>
        </>
    );
}

export default StationInfo;