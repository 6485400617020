import React from "react";
import { DialogContent, DialogTitle, TextField, DialogActions, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Req } from "../functions";

function VehicleSpec(props)
{
    const [isLoading, setLoading] = useState(false);
    const { control: spec, handleSubmit: saveSpecs } = useForm({
        mode: "onChange",
    });

    const updateSpecs = async (data) => {
        setLoading(true);
        data['vehicle'] = parseInt(props.vehicleId);
        try {
            await Req("admin/vehicles/specs", "post", data).then((res) => {
                if (!res['success']) {
                    setLoading(false);
                    return;
                }

                setLoading(false);
                props.setOpen(false);
                props.getData(props.vehicleId);
                // window.location.reload();
            });
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <>
            <DialogTitle>מאפייני רכב</DialogTitle>
            <DialogContent >
                <form onSubmit={(e) => e.preventDefault()}>
                    <Controller
                        name="name"
                        control={spec}
                        defaultValue={props.vehicle}
                        rules={{ required: "שדה זה חובה" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                type="text"
                                onChange={onChange}
                                value={value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                variant={'standard'}
                                label={"שם הרכב"}
                                InputLabelProps={{shrink: true}}
                                sx={{marginBottom: 2}}
                                fullWidth
                                disabled={isLoading}
                            />
                        )}
                    />
                    <Controller
                        name="seats"
                        control={spec}
                        defaultValue={props.seats}
                        rules={{ required: "שדה זה חובה" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                type="number"
                                onChange={onChange}
                                value={value}
                                error={!!error}
                                helperText={error ? error.message : null}
                                variant={'standard'}
                                label={"כמות מושבים"}
                                InputLabelProps={{shrink: true}}
                                sx={{marginBottom: 2}}
                                fullWidth
                                disabled={isLoading}
                            />
                        )}
                    />
                    {props.data !== undefined && <>
                        <Controller
                            name="comp"
                            control={spec}
                            defaultValue={props.data['comp']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"מודל"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="weight"
                            control={spec}
                            defaultValue={props.data['weight']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"משקל"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="width"
                            control={spec}
                            defaultValue={props.data['width']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"רוחב"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="height"
                            control={spec}
                            defaultValue={props.data['height']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"אורך"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="water"
                            control={spec}
                            defaultValue={props.data['water']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"מים"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="foam"
                            control={spec}
                            defaultValue={props.data['foam']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"קצף"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="dry"
                            control={spec}
                            defaultValue={props.data['dry']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"אבקה"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                        <Controller
                            name="max_speed"
                            control={spec}
                            defaultValue={props.data['max_speed']}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    variant={'standard'}
                                    label={"מהירות מקסימלית"}
                                    InputLabelProps={{shrink: true}}
                                    sx={{marginBottom: 2}}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                        />
                    </>}
                    <DialogActions sx={{mt: 3}}>
                        {isLoading ? <CircularProgress /> : <Button color={'success'} onClick={saveSpecs((data) => updateSpecs(data))} type="submit">שמור מפרט טכני</Button>}
                    </DialogActions>
                </form>
            </DialogContent>
        </>
    );
}
export default VehicleSpec;