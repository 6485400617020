import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Paper, TableContainer, Table, TableHead, Grid, Alert, Button, CircularProgress, TableRow, TableCell, TableBody } from '@mui/material';
import { StaffContext } from './contexts/StaffContext';

const columns = [
  { id: 'name', label: 'רכב', minWidth: 170 },
  { id: 'driver', label: 'נהג', minWidth: 170 },
  { id: 'leader', label: 'מפקד צוות', minWidth: 220 },
  { id: 'fire1', label: 'לוחם אש', minWidth: 170 },
  { id: 'fire2', label: 'לוחם אש', minWidth: 100 },
  { id: 'options', label: 'אפשרויות', minWidth: 160, },
];

function Staff() {
    const { 
        dataLoaded, rows, isDisabled, open, 
        rowId, errorMessage, setOpen, 
        handleUpdate, handleEdit, handleSubmit, handleDelete, vehicle, driver, leader, fire1, fire2,
    } = useContext(StaffContext);

    return (
    <React.Fragment>
        <Dialog open={open} onClose={() => setOpen()}> 
            <DialogTitle sx={{paddingBottom: 0}}>{rowId ? "עריכת רכב - " + vehicle : "שיבוץ רכב חדש"}</DialogTitle>
            <DialogContent>
                {errorMessage && <Alert severity={"error"} sx={{marginTop: 1}}>{errorMessage}</Alert>}

                <Grid container spacing={1}>
                    <Grid item>
                        <TextField
                            margin="dense"
                            id="vehicle"
                            label="רכב"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={vehicle}
                            onChange={(e) => handleUpdate("vehicle", e.currentTarget.value)}
                        />
                        {/* <Grid container spacing={2} sx={{mt: 0}}>
                            <Grid item xs={4}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                    onChange={(e) => handleImage(e.target)}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="contained" component="span" disableElevation fullWidth>
                                        בחר תמונה
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="outlined" disableElevation fullWidth onClick={() => setToggler(!toggler)}>הצג תמונה נוכחית</Button>
                                <FsLightbox
                                    toggler={toggler}
                                    type="image" 
                                    sources={[
                                        image
                                    ]}
                                    // onClose={() => setToggler(!toggler)}
                                /> 
                            </Grid>
                            {image !== '' &&
                            <Grid item xs={4}>
                                <Button variant="contained" color="error" disableElevation fullWidth>הסר תמונה</Button>
                            </Grid>}
                        </Grid> */}
                        {/* <TextField
                            margin="dense"
                            id="image"
                            label="תמונה"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={image}
                            onChange={(e) => handleUpdate("image", e.currentTarget.value)}
                        /> */}
                        <TextField
                            margin="dense"
                            id="driver"
                            label="נהג"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={driver}
                            onChange={(e) => handleUpdate("driver", e.currentTarget.value)}
                        />
                        <TextField
                            margin="dense"
                            id="leader"
                            label="מפקד צוות"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={leader}
                            onChange={(e) => handleUpdate("leader", e.currentTarget.value)}
                        />
                        <TextField
                            margin="dense"
                            id="fire1"
                            label="לוחם אש"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={fire1}
                            onChange={(e) => handleUpdate("fire1", e.currentTarget.value)}
                        />
                        <TextField
                            margin="dense"
                            id="fire2"
                            label="לוחם אש"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={fire2}
                            onChange={(e) => handleUpdate("fire2", e.currentTarget.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button sx={{"color": "gray", "fontWeight": "normal"}} onClick={() => setOpen()}>ביטול</Button>
                <Button color={"success"} onClick={() => handleSubmit()}>{rowId ? "עדכן" : "שמור"}</Button>
            </DialogActions>
        </Dialog>
        <Grid container sx={{marginBottom: 3}}>
            <Grid item xs={2}>
                {dataLoaded && (!isDisabled ? <><Button sx={{fontSize: 16}} variant={"contained"} disableElevation color={"info"}>הצג דף תצוגה שיבוץ מבצעי</Button></> : <Alert severity={"error"}>מודול שיבוץ מבצעי כבוי ולכן אין ברשותך מסך תצוגה.</Alert>) }
            </Grid>
        </Grid>
        <Paper>
            <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6} sx={{fontSize: 20, color: "#e2122a"}}>&larr; שיבוץ מבצעי</TableCell>
                        </TableRow>
                        <TableRow>
                            {columns.map((column, x) => (
                                <TableCell
                                key={x}
                                align={column.align}
                                style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!dataLoaded ? <TableRow><TableCell colSpan={6} align={"center"}><CircularProgress /></TableCell></TableRow> : rows.map((row, i) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                    {columns.map((column, o) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={o} align={column.align}>
                                                {column.id === 'options' ? <>
                                                    <Button disableElevation variant={"contained"} sx={{marginRight: 1}} onClick={() => handleEdit(row)}>עריכה</Button>
                                                    <Button color={"error"} disableElevation variant="outlined" onClick={() => handleDelete(row[column.id])}>הסר משיבוץ</Button>
                                                </> : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </React.Fragment>
    );
}

export default Staff;