import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Alert, Button, CircularProgress, FormControl, Fab, TablePagination, Autocomplete } from '@mui/material';
import { UsersContext } from './contexts/UsersContext';
import SearchBar from '../components/SearchBar';
import { Dialog, DialogActions, DialogContent, DialogContentText, Snackbar, Tooltip, InputLabel, Grid, Select, MenuItem, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Req } from '../functions';
import { Controller } from 'react-hook-form';

function Users() {
    const columns = [
        { id: 'user_name', label: 'שם מלא', minWidth: 170 },
        { id: 'shift', label: 'משמרת', minWidth: 170 },
        { id: 'email', label: 'דוא"ל', minWidth: 220 },
        { id: 'phone', label: 'טלפון נייד', minWidth: 170 },
        { id: 'priority', label: 'רמת הרשאה', minWidth: 100 },
        { id: 'ip_user', label: 'כתובת IP / התחברות אחרונה', minWidth: 100 },
        { id: 'options', label: 'אפשרויות', minWidth: 160, },
    ];

    const accessNames = {
        2: "מנהל",
        3: "מפקד משמרת",
        4: "לוחם אש",
        5: "נאמן כיבוי אש",
    };

    const shifts = {
        0: "ניהול",
        1: "משמרת א",
        2: "משמרת ב",
        3: "מ.ט/לא משובץ",
        4: "נאמני כיבוי אש",
    };

    const { dataLoaded, rows, handleDelete, handleSearch, loadUserData, user, userId, army, handleSubmit, SaveAndUpdate, setValue } = useContext(UsersContext);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [reset, showReset] = useState(false);
    const [open, setOpen] = useState(false);

    const handleReset = async (id) => {
        try {
            await Req("admin/users/reset", "post", { "userid": id }).then((data) => {
                if (!data.success) return;
                showReset(true);
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (data = '') => {
        loadUserData(data);
        setOpen(true);
    };

    return (
      <>
       <Dialog open={open} onClose={() => setOpen(!open)} fullWidth>
            <DialogTitle sx={{paddingBottom: 0}}>{userId !== null ? "עריכת משתמש" : "הוספת משתמש חדש"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} sx={{marginTop: "15px"}}>
                    <Grid item xs={6} alignSelf={'end'}>
                        <Controller
                            name="user_name"
                            control={user}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value ?? ''}
                                    error={!!error}
                                    label={"שם מלא"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="priority"
                            control={user}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Select fullWidth onChange={onChange} value={value ?? ''} label={'הרשאה'} placeholder={'הרשאה'}> 
                                    {Object.keys(accessNames).map((item, i) => {
                                        return (
                                            <MenuItem value={item} key={i}>{accessNames[item]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="email"
                            control={user}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="text"
                                    onChange={onChange}
                                    value={value ?? ''}
                                    error={!!error}
                                    label={"דואר אלקטרוני"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="phone"
                            control={user}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    type="tel"
                                    onChange={onChange}
                                    value={value ?? ''}
                                    error={!!error}
                                    label="טלפון נייד"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    {army === true && 
                    <Grid item xs={12} sx={{mt: 2}}>
                        <InputLabel shrink>משמרת</InputLabel>
                        <Controller
                            name="shift"
                            control={user}
                            rules={{ required: "שדה זה חובה" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Select
                                    value={value ?? ''}
                                    fullWidth
                                    onChange={onChange}
                                >
                                    {Object.keys(shifts).map((item, i) => {
                                        return (
                                            <MenuItem value={item} key={i}>{shifts[item]}</MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </Grid>}
                    <Grid item xs={12} sx={{mt: 2}}>
                        <Controller
                            name="distribution"
                            control={user}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    defaultValue={[]}
                                    value={value ?? []}
                                    options={[]}
                                    onChange={(event, newInputValue) => {
                                        setValue("distribution", newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="רשימות תפוצה" placeholder="הקלד רשימות תפוצה" />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(!open)} sx={{"color": "gray", "fontWeight": "normal"}}>ביטול</Button>
                <Button onClick={handleSubmit((data) => SaveAndUpdate(setOpen, data))} color={"success"}>{userId ? "עדכן" : "שמור"}</Button>
            </DialogActions>
        </Dialog>

        <Grid container>
            <Grid item xs={6}>
                <SearchBar handleChange={(data) => handleSearch(data)} />
            </Grid>
            <Grid item xs={6} sx={{textAlign: "end"}}>
                <Tooltip title={"הוספת משתמש חדש"}>
                    <Fab color="success" aria-label="add" size='large' onClick={() => handleEdit()}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
        <Paper sx={{ width: '100%' }}>
            <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; ניהול מכשירים</TableCell>
                        </TableRow>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.label}
                                align={column.align}
                                style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                {columns.map((column, x) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={x} align={column.align}>
                                        {column.id === 'options' ? <>
                                                <Button variant={"contained"} disableElevation sx={{marginRight: "10px"}} onClick={() => handleEdit(row)}>עריכה</Button>
                                                <Button variant={"outlined"} disableElevation sx={{marginRight: "10px"}} color={"success"} onClick={() => handleReset(row.id)}>אפס סיסמה</Button>
                                                <Button variant={"outlined"} color={"error"}  onClick={() => handleDelete(row.id)}>מחיקה</Button>
                                            </> : column.id === 'priority' ? accessNames[value] : ((column.id === 'shift' && army === true) ? shifts[value] : value)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"רשומות לעמוד: "} 
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                key={"search"}
            />
        </Paper>
        <Snackbar open={reset} autoHideDuration={5000} onClose={() => showReset(!reset)}>
            <Alert severity="success" sx={{ width: '100%' }}>
                נשלחה הודעה עם סיסמה חדשה למשתמש זה.
            </Alert>
        </Snackbar>
    </>
  );
}

export default Users;