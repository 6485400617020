import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Req } from '../../functions';

const StationContext = React.createContext();
function StationProvider (props) {
    let location = useParams();
    const [ dataLoaded, setIsLoading ] = useState(true);
    const [ error, setError ] = useState('');
    const [ data, setData ] = useState([]);
    const [ settings, setSettings ] = useState({});
    const [dateState, setDateState] = useState(new Date());
    const getData = async () => {
        await Req('teams', 'post', {
            "sid": location.id,
        }).then(function(res) {
            if (!res['success']) {
                setError(res['error']);
                return;
            }
            setIsLoading(false);
            setData(res.data);
            setSettings(res.settings);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
      setInterval(() => setDateState(new Date()), 30000);
    }, [setDateState]);

    return (
        <StationContext.Provider
            value={{
                error,
                dataLoaded,
                data,
                settings,
                dateState,
            }}
        >
            {props.children}
        </StationContext.Provider>
    )
}

export { StationProvider, StationContext }