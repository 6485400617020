import React, { useEffect, useState } from 'react';
import config, { Cookies, Req } from '../../functions';

const PageRouteContext = React.createContext();

const PageRouteContextProvider = (props) => {
    const [name, setName] = useState('');
    const [access, setAccess] = useState(0);
    const [stationId, setStationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [stations, setStations] = useState([]);
    const [stationName, setStationName] = useState('מצב מנהל');
    const [logs, setLogs] = useState([]);

    const handleStationChange = async (id) => {
      try {
        await Req("admin/switch_station", "post", {'id': id}).then((response) => {
            if (!response['success']) return;
            setStationId(id);
            setStationName(response['name']);
            getData();
            
            if (window.location.pathname !== '/')
            {
                if (id === null)
                {
                    window.location.href='/';
                    return;
                }
                window.location.reload();
            }
        });
      }
      catch (error) {
        console.log(error);
      }
    };
   
    const getData = async () => {
        try {
            await fetch(config.base + '/admin/me', {
                method: "OPTIONS",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('token')
                }),
            }).then(response =>
                response.json()
            )
            .then(data => {
                if (!data.success) {
                    Cookies.remove('token');
                    window.location.href = '/';
                    return;
                }
                setName(data.data.name);
                setStationId(data.data.stationId);

                if (data.data.access === 1)
                {
                    setStations(data.stations);
                    if (stationId !== data.data.stationId)
                    {
                        const index = Object.keys(data.stations).findIndex((key) => data.stations[key]['id'] === parseInt(data.data.stationId));
                        setStationName(data.stations[index]['name']);
                    }
                }

                setAccess(parseInt(data.data.access));
                setIsLoading(false);
                
                setDataLoaded(true);
                setLogs(data.logs);
            });
        }
        catch (error) {
            console.log(error);
            // Cookies.remove('token');
            // window.location.href = '/';
        }
    }

    useEffect(() => {
        document.title = 'התחנה';
        if (Cookies.get('token') !== undefined) getData();
    }, []);

    return (
        <PageRouteContext.Provider
            value={{
                name,
                access,
                stationId,
                isLoading,
                dataLoaded,
                stations,
                logs,
                handleStationChange,
                stationName,
            }}
        >
            {props.children}
        </PageRouteContext.Provider>
    )
}

export { PageRouteContext, PageRouteContextProvider }