import React, { useContext } from 'react';
import { TableCell, TableRow, TableHead, TableBody, Paper, TablePagination, Table, CircularProgress, Grid, Button, TableContainer, Tooltip, Dialog, DialogTitle, DialogContent, } from '@mui/material';
import { CarReportsContext } from './contexts/CarReportsContext';
import SearchBar from '../components/SearchBar';
import { ZoomInMapOutlined, ErrorOutlineRounded, CheckCircleOutline } from '@mui/icons-material';

const columns = [
  { id: 'vehicle', label: 'רכב', minWidth: 170 },
  { id: 'type', label: 'סוג ביקורת', minWidth: 170 },
  { id: 'date', label: 'תאריך/זמן', minWidth: 170 },
  { id: 'reviewedBy', label: 'מבקר', minWidth: 170 },
  { id: 'options', label: 'אפשרויות' }
];

function CarReports()
{
    const { rows, dataLoaded, open, handleView, data } = useContext(CarReportsContext);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let x = 0;

    return (
        <>
        <Dialog open={open} fullWidth onClose={() => handleView()}>
            <DialogTitle>צפייה בביקורת</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>תיאור פריט</TableCell>
                            <TableCell>סטטוס</TableCell>
                            <TableCell>כמות</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data ? Object.keys(data).map((item, i) => {
                        console.log(data);
                        var row = data[item];
                        if (row.value !== '') x ++;
                        return (
                            <TableRow key={i}>
                                <TableCell>{row.value === null ? <strong>{row.name}</strong> : x}</TableCell>
                                <TableCell>{row.value === null ? '' : row.name}</TableCell>
                                {row.value === null ? <TableCell></TableCell> : <TableCell>{row.status === 1 || row.status === true ? <CheckCircleOutline color="success" /> : <ErrorOutlineRounded color="error" />}</TableCell>}
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        )
                    }): null}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
        <Grid container>
            <Grid item xs={6}>
                <SearchBar />
            </Grid>
        </Grid>
        <Paper sx={{ width: '100%' }}>
        <TableContainer>
            <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; ביקורות רכבי כיבוי אש</TableCell>
                    </TableRow>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.label}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, o) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={o} align={column.align}>{column.id === 'options' ? <>
                                        <Tooltip title={"צפה בביקורת"}>
                                            <Button onClick={() => handleView(row.items)}><ZoomInMapOutlined /></Button>
                                        </Tooltip>
                                    </> : value}</TableCell>
                                );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"רשומות לעמוד: "} 
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            key={"search"}
        />
        </Paper>
        </>
    );
}

export default CarReports;