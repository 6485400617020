import React, { useContext } from 'react';
import { Paper, TextField, InputLabel, FormControl, Grid, Switch, CircularProgress, Button, Divider, } from '@mui/material';
import { SettingsContext } from './contexts/SettingsContext';
import { Controller } from 'react-hook-form';

function Settings(props)
{
    const { data, dataLoaded, settings, watch, handleSubmit, onClick, disabledButton, setValue } = useContext(SettingsContext);

    if (dataLoaded)
        return (<Paper sx={{p: 3, justifyContent: "center", alignSelf: "center", alignItems: "center", display: "flex", flexDirection: "column", lineHeight: 3}}><CircularProgress />טוען..</Paper>);

    return (
        <>
            <h3 sx={{fontSize: 20, color: "#e2122a"}}>&larr; הגדרות</h3>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper sx={{p: 5}}>
                        <h4>פרטים כללים</h4>
                        <FormControl fullWidth sx={{marginTop: 2}}>
                            <InputLabel shrink>שם התחנה</InputLabel>
                            <Controller
                                name="name"
                                control={settings}
                                defaultValue={data.setting[0]['app_name']}
                                rules={{ required: "שדה זה חובה" }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        type="text"
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        variant={'standard'}
                                        InputLabelProps={{shrink: true}}
                                        value={value}
                                        disabled={disabledButton}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{marginTop: 3}}>
                            <InputLabel shrink>טלפון</InputLabel>
                            <Controller
                                name="phone"
                                control={settings}
                                rules={{ required: "שדה זה חובה", pattern: { value: /^\d+$/ } }}
                                defaultValue={data.setting[0]['phoneSt']}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        type="tel"
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        variant={'standard'}
                                        InputLabelProps={{shrink: true}}
                                        value={value}
                                        disabled={disabledButton}
                                    />
                                )}
                            />
                        </FormControl>
                        {props.access === 1 && 
                        <FormControl fullWidth sx={{marginTop: 3}}>
                            <InputLabel shrink>תחנה צבאית</InputLabel>
                            <Controller
                                name="is_army"
                                control={settings}
                                defaultValue={data.setting[0]['is_army']}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Switch 
                                        onChange={onChange}
                                        checked={!!value}
                                        variant={'standard'} 
                                        disabled={disabledButton} />
                                )}
                            />
                        </FormControl>}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mt: 3}}>
                <Grid item xs={6}>
                    <Paper sx={{p: 5}}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <h4>תפריט</h4>
                            {Object.keys(data.setting[0]['menu']).map((key, object) => {
                                const item = data.setting[0]['menu'][key];
                                const enable = `menu[${key}][enable]`;
                                const url = `menu[${key}][url]`;
                                const title = `menu[${key}][title]`;
                                const img = `menu[${key}][img]`;
                                setValue(title, item.title);
                                if (item.img !== undefined && item.img !== '')
                                    setValue(img, item.img);

                                return (
                                    <div key={key}>
                                        <Grid container sx={{mb: 1}}>
                                            <Grid item xs={1} alignSelf={'center'}>
                                                <Controller
                                                    name={enable}
                                                    control={settings}
                                                    defaultValue={item.enable === "true" ? true : false}
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <Switch 
                                                            onChange={onChange}
                                                            checked={!!value}
                                                            variant={'standard'} disabled={props.access === 1 ? disabledButton : true}  />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3} alignSelf={'center'}>
                                                {item.title}
                                            </Grid>
                                            { ((watch(enable) === undefined && item.enable === "true") || (watch(enable) !== undefined && watch(enable) === true)) && (watch(url) !== undefined || item.url !== '') && <Grid item xs={8} alignSelf={'center'}>
                                                <Controller
                                                    name={url}
                                                    control={settings}
                                                    defaultValue={item['url']}
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <TextField value={value} onChange={onChange} error={error} fullWidth variant={'standard'} />
                                                    )}
                                                    disabled={disabledButton}
                                                />
                                            </Grid>}
                                        </Grid>
                                    </div>
                                );
                            })}

                            <Divider sx={{mt: 3}} />
                            <Button size={'large'} sx={{mt: 2}} onClick={handleSubmit((data) => onClick(data))} disabled={disabledButton}>שמור ועדכן הגדרות</Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

        </>
    );
}

export default Settings;