import React, { useContext } from 'react';
import { Box, Toolbar, Typography, Paper } from '@mui/material';
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  Outlet,
} from "react-router-dom";
import { User, Cookies } from './functions';

// Contexts
import { PageRouteContext } from './sources/contexts/RouteContext';
import { UsersProvider } from './sources/contexts/UsersContext';
import { StorageProvider } from './sources/contexts/StorageContext';
import { StaffProvider } from './sources/contexts/StaffContext';
import { StationProvider } from './sources/contexts/StationContext';
import { GalleryProvider } from './sources/contexts/GalleryContext';
import { BlockedProvider } from './sources/contexts/BlockedContext';
import { ContactsProvider } from './sources/contexts/ContactsContext';
import { SignProvider } from './sources/contexts/SignContext';
import { SettingsProvider } from './sources/contexts/SettingsContext';

// Pages
import Dashboard from './sources/Dashboard';
import Users from './sources/Users';
import Storage from './sources/Storage';
import Staff from './sources/Staff';
import LoginPage from './sources/Login';
import StationInfo from './sources/Station';
import Gallery from './sources/Gallery';
import Blocked from './sources/Blocked';
import Contacts from './sources/Contacts';
import Sign from './sources/Sign';
import CarReports from './sources/CarReports';
import Settings from './sources/Settings';

// Components
import Header from './components/Header';
import { GppBad } from '@mui/icons-material';
import { CarReportsProvider } from './sources/contexts/CarReportsContext';
import Contact from './sources/Contact';
import { VehiclesProvider } from './sources/contexts/VehiclesContext';
import Vehicles from './sources/Vehicles';
import EditVehicle from './components/EditVehicle';
import Guides from './sources/Guides';
import { GuidesProvider } from './sources/contexts/GuidesContext';
import EditGuide from './components/EditGuide';

import makeapp from "./assets/img/makeapp.png";

const drawerWidth = 240;

export default function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        {/* LOGGED IN */}
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="" element={<Dashboard />} />
          <Route path="users" element={<UsersProvider><Users /></UsersProvider>} />
          <Route path="storage" element={<StorageProvider><Storage /></StorageProvider>} />
          <Route path="staff" element={<StaffProvider><Staff /></StaffProvider>} />
          <Route path="status" element={<StationProvider><StationInfo /></StationProvider>} exact />
          <Route path="gallery" element={<GalleryProvider><Gallery /></GalleryProvider>} />
          <Route path="blocked" element={<BlockedProvider><Blocked /></BlockedProvider>} />
          <Route path="contacts" element={<ContactsProvider><Contacts /></ContactsProvider>} />
          <Route path="sign" element={<SignProvider><Sign /></SignProvider>} />
          <Route path="car_reports" element={<CarReportsProvider><CarReports /></CarReportsProvider>} />
          <Route path="vehicles" element={<VehiclesProvider><Vehicles /></VehiclesProvider>} />
          <Route path="vehicles/:id" element={<VehiclesProvider><EditVehicle /></VehiclesProvider>} />
          <Route path="guides" element={<GuidesProvider><Guides /></GuidesProvider>} />
          <Route path="guides/:id" element={<GuidesProvider><EditGuide /></GuidesProvider>} />
          <Route path="settings" element={<SettingsProvider><Settings /></SettingsProvider>} />
          <Route path="signout" element={<Signout />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
        {/* NON-LOGGED */}
        <Route path="/user" element={<PublicOutlet />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="status/:id" element={<StationProvider><StationInfo /></StationProvider>} exact />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

function PrivateOutlet() {
  const { name, access, stationId, stationName, stations, handleStationChange } = useContext(PageRouteContext);
  const auth = User.isLogin();
  return auth ? <>
    <Box sx={{ display: 'flex' }}>
      <Header name={name} access={access} stationId={stationId} stations={stations} handleStationChange={handleStationChange} stationName={stationName} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Typography component={"div"}></Typography>
          <Outlet access={access} />
      </Box>

      <footer>
        <img src={makeapp} alt={'MAKEAPP'} />
      </footer>
    </Box>
  </> : <Navigate to="/user/login" />;
}

function PublicOutlet() {
  const auth = User.isLogin();
  return auth ? <Navigate to="/" /> : <Outlet />;
}

function NoMatch() {
  return(
    <>
    <Paper component="div" sx={{ textAlign: "center", backgroundColor: "transparent", paddingRight: 20, paddingLeft: 20, height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <GppBad sx={{fontSize: 60}} color={"error"} />
      <h2>הדף אינו נמצא</h2>
      <Typography component={"p"} sx={{color: "gray", fontSize: 14}}>הדף שניסית להגיע אליו אבד, הוסר או שאינך ברשותך מספיק הרשאות לגשת לעמוד זה.<br />אם תקלה זו חוזרת על עצמה - פנה לממונה בארגונך.</Typography>
    </Paper>
    </>
  );
}

function Signout() {
  Cookies.remove('token');
  window.location.href='/';
  return ('');
}


// export default App;