import _Cookie from 'js-cookie';
import axios from 'axios';
const config = { base: "https://api.firestation.online" };
export default config;

const Cookies = {
    set(name, value) {
        _Cookie.set(name, value);
        console.log("Cookie " + name + " is set.");
    },
    get(name) {
        return _Cookie.get(name);
    },
    remove(name) { return _Cookie.remove(name); },
}

const User = {
    isLogin() {
        if (Cookies.get('token') !== undefined) return true;
        return false;
    }
}

const Req = async (url, method, data = {}) => {
    let headers = {
        "Content-Type": 'application/json',
    };

    if (User.isLogin() === true)
        headers['Authorization'] = "Bearer " + Cookies.get('token');

    return await axios({
        //withCredentials: true,
        method: method,
        baseURL: config.base,
        url: url,
        data: JSON.stringify(data),
        headers: headers
    }).then(response => {
        return response.data;
    });
}

export { Cookies, User, Req }