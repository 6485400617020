import axios from 'axios';
import React, { Component } from 'react';
import { Cookies, Req, User } from '../../functions';

function createData(name, driver, leader, fire1, fire2, image, options) {
    return { name, driver, leader, fire1, fire2, image, options };
}

const StaffContext = React.createContext();
class StaffProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            dataLoaded: false,
            isDisabled: true,
            open: false,

            // fields
            rowId: null,
            vehicle: "",
            driver: "",
            leader: "",
            fire1: "",
            fire2: "",
            // image: "",
            vehicles: [],
            errorMessage: ""
        };
    }

    setOpen = () => {
        this.setState({ open: (this.state.open ? false : true) });
    };

    handleUpdate = (key, value) => {
        this.setState({ [key]: value });
    };

    handleImage = (event) => {
        this.setState({ image: event.files[0] });
    };

    handleSubmit = async () => {
        let d = this;
        let data = {
            "vehicle": this.state.vehicle, 
            // "image": this.state.image, 
            "driver": this.state.driver, 
            "leader": this.state.leader,
            "fire1": this.state.fire1, 
            "fire2": this.state.fire2 
        };

        if (this.state.rowId)
            data['id'] = this.state.rowId;

        const postData = new FormData();
        for (var key in data) {
            postData.append(key, data[key]);
        }

        try {
            await axios.post("https://api.firestation.online/admin/staff/edit", postData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': (User.isLogin() ? "Bearer " + Cookies.get('token') : ""),
                }
            }).then((res) => {
                const error = res.data.error;
                const success = res.data.success;

                if (success) {
                    d.setState({ 
                        errorMessage: "", 
                        open: false, 
                        dataLoaded: false,
                    });
                    d.getData();
                    return;
                }

                d.setState({
                    errorMessage: error
                });
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleEdit = async (data) => {
        this.setState({
            open: true, 
            rowId: data.options,
            vehicle: data.name.props.children[0], 
            // image: data.image, 
            driver: data.driver, 
            leader: data.leader,
            fire1: data.fire1, 
            fire2: data.fire2,
        });
    };

    handleDelete = async (id) => {
        let d = this;
        await Req("admin/staff/delete", "post", { "id" : id }).then((response) => {
            if (!response['success'])
                return;
            d.getData();
        });
    };

    getData = async () => {
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/staff", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach((e) => {
                        loadedData.push(createData(
                            <>
                                {e.vehicle}
                                <br />
                                <img src={e.image_url} alt={e.vehicle} width={80} />
                            </>,
                            e.driver,
                            e.leader,
                            e.fire1,
                            e.fire2,
                            e.image_url,
                            e.id,
                        ));
                    });
                    e.setState({ isDisabled: data.isDisabled, vehicles: data.vehicles });
                }
            });
            e.setState({
                rows: loadedData,
                dataLoaded: true,
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
    }
    
    render() {
        return (
            <StaffContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
                handleEdit: this.handleEdit,
                handleSubmit: this.handleSubmit,
                handleUpdate: this.handleUpdate,
                handleDelete: this.handleDelete,
                setOpen: this.setOpen,
                // handleImage: this.handleImage,
            }}>
                {this.props.children}
            </StaffContext.Provider>
        );
    }
}

export { StaffContext, StaffProvider }