import React, { Component } from 'react';
import { Req } from '../../functions';

function createData(id, name, role, phone) {
    return { id, name, role, phone };
}

const ContactsContext = React.createContext();
class ContactsProvider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            dataLoaded: false,
            rowId: null,
            errorMessage: "",
            open: false,
            name: "",
            role: "",
            phone: ""
        };
    }

    handleEdit = (data) => {
        this.setState({ 
            open: true,
            name: data.name,
            role: data.role,
            phone: data.phone,
            rowId: data.id,
            errorMessage: "",
        });
    };

    handleAdd = () => {
        this.setState({
            open: true,
            name: "",
            role: "",
            phone: "",
            rowId: null,
            errorMessage: ""
        });
    };

    handleSubmit = async () => {
        let d = this;
        let data = {
            "name": this.state.name,
            "role": this.state.role,
            "phone": this.state.phone,
        };

        if (this.state.rowId)
            data['id'] = this.state.rowId;

        try {
            await Req("admin/contacts/" + (data['id'] ? "edit" : "add"), "post", data).then(function(data) {
                if (data.success)
                {
                    d.setState({ errorMessage: "", open: false, dataLoaded: false });
                    d.getData();
                }
                else
                {
                    d.setState({ errorMessage: data.error });
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    closeEdit = () => {
        this.setState({ open: false });
    };

    setData = (key, value) => {
        this.setState({ [key]: value });
    };

    handleDelete = async (id) => {
        let d = this;
        try {
            await Req("admin/contacts/delete", "post", { "id": id }).then(function(data) {
               if (data.success) {
                   d.setState({ dataLoaded: false });
                   d.getData();
               } 
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleSearch = (value) => {
        this.setState({ dataLoaded: false });
        const filteredRows = this.state.defaultRows.filter((row) => {
            return row.name.toLowerCase().includes(value.toLowerCase()) || row.userName.toLowerCase().includes(value.toLowerCase()) || row.email.toLowerCase().includes(value.toLowerCase()) || row.phone.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({ rows: filteredRows, dataLoaded: true });
    };

    getData = async () => {
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/contacts", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach((e) => {
                        loadedData.push(createData(
                            e.id,
                            e.name,
                            e.role,
                            e.phone
                        ));
                    });
                }
            });
            e.setState({
                rows: loadedData,
                dataLoaded: true
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
    }
    
    render() {
        return (
            <ContactsContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
                handleSearch: this.handleSearch,
                setData: this.setData,
                closeEdit: this.closeEdit,
                handleEdit: this.handleEdit,
                handleDelete: this.handleDelete,
                handleSubmit: this.handleSubmit,
                handleAdd: this.handleAdd
            }}>
                {this.props.children}
            </ContactsContext.Provider>
        );
    }
}

export { ContactsContext, ContactsProvider }