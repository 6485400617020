import React from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Switch, Snackbar, Alert, Tooltip, IconButton, Grid, Dialog, DialogContent, DialogActions, DialogTitle, Button, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Req } from "../functions";
import { GuidesContext } from "./contexts/GuidesContext";
import SearchBar from "../components/SearchBar";
import { Controller, useForm } from "react-hook-form";

function Guides() 
{
    const { dataLoaded, columns, rows, setRows, getData } = useContext(GuidesContext);
    const [snackbar, setSnackbar] = useState(false);
    const [open, setOpen] = useState(false);

    const changeStatus = async (id, v) => {
        let items = [...rows];
        let index = items.findIndex(obj => obj.id === id);
        let item = {...items[index]};
        item.status = v.target.checked;
        items[index] = item;
        setRows(items);
        await Req("admin/guides/status", "post", {'id': item.id, 'value': item.status}).then((res) => {
            const success = res.success;
            if (!success) return;
            setSnackbar(true);
            getData()
        });
    };

    let navigate = useNavigate();
    const { control: add, handleSubmit: addNewGuide } = useForm({ mode: "onChange" });
    const submitNewGuide = async (data) => {
        await Req("admin/guides/add", "post", data).then((res) => {
            if (!res['success'])
                return;
            
            window.location.href= '/guides/' + res['insert_id'];
            setOpen(false);
        });
    };

    const handleDelete = async (id) => {
        const conf = window.confirm ("האם הינך בטוח/ה שברצונך להסיר מאמר זה?");
        if (!conf) return;

        await Req("admin/guides/delete", "post", {'guideId': id}).then((res) => {
            if (!res['success'])
                return;
            
            getData();
        });
    };

    return (
        <>
            <Snackbar open={snackbar} autoHideDuration={2500}><Alert severity={'success'}>הפעולה בוצעה בהצלחה</Alert></Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SearchBar />
                </Grid>
                <Grid item xs={6} textAlign={'end'}>
                    <Tooltip title="יצירת מאמר חדש"><IconButton color="success" onClick={() => setOpen(!open)}><Add /></IconButton></Tooltip>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogTitle>יצירת מאמר חדש</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Controller
                            control={add}
                            name="title"
                            rules={{required: true}}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <FormControl fullWidth sx={{mb: 2}}>
                                    <TextField type={'text'} variant={'standard'} error={!!error} helperText={error ? error.message : ''} name={name} label={'שם המאמר'} InputLabelProps={{shrink: true}} onChange={onChange} value={value ?? ''} />
                                </FormControl>
                            )}
                        />
                        <DialogActions>
                            <Button color={'success'} onClick={addNewGuide((data) => submitNewGuide(data))}>שמור</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Paper>
                <TableContainer>
                    <Table aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={6} sx={{fontSize: 20, color: "#e2122a"}}>&larr; ניהול הדרכה</TableCell>
                            </TableRow>
                            <TableRow>
                                {columns.map((column, x) => (
                                    <TableCell
                                        key={x}
                                        align={column.align}
                                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                                    >
                                    {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!dataLoaded ? <TableRow><TableCell colSpan={6} align={"center"}><CircularProgress /></TableCell></TableRow> : rows.map((row, i) => {
                                    return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                        {columns.map((column, o) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={o} align={column.align}>
                                                    {column.id === 'imgUrl' ? <img src={value} width={100} alt={row.title} /> : (column.id === 'status' ? <Switch checked={value} onChange={(v) => changeStatus(row.id, v)} /> : (column.id !== 'options' ? value : <>
                                                        <Tooltip title="עריכה"><IconButton onClick={() => navigate('/guides/' + row.id)}><Edit /></IconButton></Tooltip>
                                                        <Tooltip title="מחיקה"><IconButton onClick={() => handleDelete(row.id)}><Delete /></IconButton></Tooltip>
                                                    </>))}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

export default Guides;
