import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Alert, Button, CircularProgress, Fab, Typography } from '@mui/material';
import { GalleryContext } from './contexts/GalleryContext';
import SearchBar from '../components/SearchBar';
import { Dialog, DialogActions, DialogContent, Tooltip, Grid, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const columns = [
  { id: 'url', label: 'תמונה/קישור', minWidth: 170 },
  { id: 'status', label: 'סטטוס', minWidth: 170 },
  { id: 'expired', label: 'תאריך תפוגה', minWidth: 220 },
  { id: 'options', label: 'אפשרויות' }
];

function Gallery() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { 
        rows, 
        dataLoaded, 
        handleSearch, 
        open, 
        handleEdit, 
        closeEdit,
        setData,
        handleAdd,
        handleSubmit,
        rowId,
        errorMessage,
        handleDelete,
        handleImage,
        file,
        expired
     } = useContext(GalleryContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

  return (
      <>
        <Dialog open={open} onClose={() => closeEdit()}>
            <DialogTitle sx={{paddingBottom: 0}}>{rowId ? "עריכת תמונה" : "הוספת תמונה חדשה"}</DialogTitle>
            <DialogContent>
                {errorMessage && <Alert severity={"error"} sx={{marginTop: 1}}>{errorMessage}</Alert>}
                <br />
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleImage(e.target)}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" disableElevation fullWidth>
                       {file === '' ? " בחר תמונה" : file['name']}
                    </Button>
                </label>
                <TextField
                    sx={{mt: 3}}
                    margin="dense"
                    id="expired"
                    label="תאריך תפוגה"
                    type="date"
                    fullWidth
                    variant="standard"
                    value={expired}
                    onChange={(e) => setData("expired", e.currentTarget.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeEdit()} sx={{"color": "gray", "fontWeight": "normal"}}>ביטול</Button>
                <Button onClick={() => handleSubmit()} color={"success"}>{rowId ? "עדכן" : "שמור"}</Button>
            </DialogActions>
        </Dialog>

        <Grid container>
            <Grid item xs={6}>
                <SearchBar handleChange={(data) => handleSearch(data)} />
            </Grid>
            <Grid item xs={6} sx={{textAlign: "end"}}>
                <Tooltip title={"הוספת תמונה חדשה"}>
                    <Fab color="success" aria-label="add" size='large' onClick={() => handleAdd()}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
        <Paper sx={{ width: '100%' }}>
        <TableContainer>
            <Table aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} sx={{fontSize: 20, color: "#e2122a"}}>&larr; סליידר תמונות</TableCell>
                    </TableRow>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.label}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth, fontSize: 16 }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!dataLoaded ? <TableRow><TableCell colSpan={7} align={"center"}><CircularProgress /></TableCell></TableRow> : rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, o) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={o} align={column.align}>
                                {column.id === 'options' ? <React.Fragment>
                                    <Button variant={"contained"} disableElevation sx={{marginRight: "10px"}} onClick={() => handleEdit(row)}>עריכה</Button>
                                    {!row.prevent && <Button variant={"outlined"} color={"error"}  onClick={() => handleDelete(row.id)}>מחיקה</Button>}
                                </React.Fragment> : column.id === 'url' ? <Typography component={"a"} href={value} target={"_blank"}>{value}</Typography> : column.id === 'status' ? (value === 1 ? <span style={{color: "green"}}>פעיל</span> : <span style={{color: "red"}}>פג תוקף</span>) : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"רשומות לעמוד: "} 
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            key={"search"}
        />
        </Paper>
    </>
  );
}

export default Gallery;