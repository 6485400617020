import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Req } from '../../functions';

const VehiclesContext = React.createContext();
function VehiclesProvider (props) {
    const [dataLoaded, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [lines, setLines] = useState([]);

    const columns = [
        { id: 'vehicle', label: 'רכב', flex: 1, },
        { id: 'seats', label: 'כמות מושבים', flex: 1 },
        { id: 'chief', label: 'רכב פיקוד', flex: 1 },
        { id: 'options', label: 'אפשרויות', flex: 1 }
    ];

    let { id } = useParams();

    const [add, setAdd] = useState(false);

    const processRowInsert = async (data, isDriver) => {
        try {
            data['id'] = parseInt(id);
            if (isDriver) data['isDriver'] = true;
            
            await Req("admin/vehicles/items/add", "post", data).then((res) => {
                if (!res['success'])
                    return;
                
                getData(id);
                setAdd(false);
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    const processRowDelete = async (id, vehicle, isDriver) => {
        const conf = window.confirm("האם הינך בטוח/ה שברצונך להסיר פריט זה?");
        if (!conf) 
            return;

        try {
            await Req("admin/vehicles/items/delete", "post", {'id': id, 'vehicle': vehicle, 'isDriver': isDriver}).then((res) => {
                if (!res['success'])
                    return;
                getData(vehicle);
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    const processRowUpdate = async (newRow: any) => {
        const updatedRow = { ...newRow };

        if (updatedRow['value'] === undefined)
            updatedRow['type'] = 'drivers';
        
        try {
            await Req("admin/vehicles/items", "post", updatedRow).then((res) => {
                getData(id);
                console.log('being refreshed..');
            });
        }
        catch (error) {
            console.log(error);
        }

        return updatedRow;
    }

    const getData = async (id) => {
        setIsLoading(false);
        setRows([]);
        setLines([]);
        try {
            await Req("admin/vehicles/" + (id !== undefined ? id : '') , "get").then((response) => {
                if (!response['success'])
                    return;

                setIsLoading(true);
                if (id !== undefined)
                    setRows(response['data']);
                else
                    setLines(response['data']);
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData(id);
    }, [id]);
    
    return (
        <VehiclesContext.Provider value={{
            columns,
            dataLoaded,
            rows,
            getData,
            lines,
            processRowUpdate,
            processRowInsert,
            processRowDelete,
            add,
            setAdd
        }}>
            {props.children}
        </VehiclesContext.Provider>
    );
}

export { VehiclesContext, VehiclesProvider }