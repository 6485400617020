import React from 'react';
import { TextField, Container, Button, Alert, Grid } from '@mui/material';
import '../assets/css/login.css';
import logo from '../assets/img/logo_white.svg';
import { Req, Cookies } from '../functions';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {username: '', password: '', error: '', errorMode: 'error'};

    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    document.body.classList.add('loginPage');
  }

  handleUsername(event) {
    this.setState({username: event.target.value});
  }

  handlePassword(event) {
    this.setState({password: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.username === '' || this.state.password === '')
    {
      this.setState({
        error: "אנא מלא את הטופס כראוי!",
        errorMode: "error"
      });
      return;
    }

    const form = this;
    Req("admin/auth", "post", {
      "username": this.state.username,
      "password": this.state.password,  
    })
    .then(function(response) {
      if (response['success'] && response['access_token']) {
        Cookies.set("token", response['access_token']);
        setTimeout(function() {
          window.location.href = '/';
        }, (2 * 1000));
      }
      
      form.setState({
        errorMode: (response['success'] ? "success" : "error"),
        error: response['error']
      });
    });
    return false;
  }
  render() {
    return <React.Fragment>
      <div className="background-overlay"></div>
      <div className="App">
        <img src={logo} alt="התחנה" className="logo" />
        <h1>התחנה</h1>
        <h2>- כניסה למורשים בלבד -</h2>

        <Container maxWidth="sm" className="wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col">
                <TextField id="standard-basic" label="שם משתמש" variant="standard" className="textField" fullWidth value={this.state.username} onChange={this.handleUsername} />
              </div>
              <div className="col">
                <TextField id="standard-basic" type={"password"} label="סיסמה" variant="standard" fullWidth value={this.state.password} onChange={this.handlePassword} />
              </div>
            </div>
            <Grid container sx={{mt: 2}} spacing={2}>
              <Grid item xs={6}><Button variant="contained" type="submit" color="error" fullWidth disableElevation onClick={this.handleSubmit}>כניסה</Button></Grid>
              <Grid item xs={6} sx={{alignSelf: "center", textAlign: "end"}}>
                <Button onClick={() => window.location.href='/contact'} fullWidth variant={'outlined'} color="warning">יצירת קשר</Button>
              </Grid>
            </Grid>
            <div className="row">
              <div className="col">
                {this.state.error && <Alert severity={this.state.errorMode}>{this.state.error}</Alert>}
              </div>
            </div>
          </form>
        </Container>
      </div>
    </React.Fragment>;
  }
}

export default LoginPage;