import axios from 'axios';
import React, { Component } from 'react';
import { Cookies, Req, User } from '../../functions';

function createData(id, url, status, expired, prevent) {
    return { id, url, status, expired, prevent };
}

const GalleryContext = React.createContext();
class GalleryProvider extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            defaultRows: [],
            dataLoaded: false,
            open: false,
            file: "",
            expired: "",
            rowId: null,
            errorMessage: "",
        };
    }
    
    handleEdit = (data) => {
        this.setState({ 
            open: true,
            file: "",
            expired: data.expired,
            rowId: data.id,
            errorMessage: "",
        });
    };

    handleAdd = () => {
        this.setState({
            open: true,
            file: "",
            expired: "",
            rowId: null,
            errorMessage: ""
        });
    };

    closeEdit = () => {
        this.setState({ open: false });
    };
    
    setData = (key, value) => {
        this.setState({ [key]: value });
    };

    handleImage = (e) => {
        this.setState({ file: e.files[0] });
    };

    handleSubmit = async () => {
        let d = this;

        const postData = new FormData();
        postData.append("file", this.state.file);
        postData.append("expired", this.state.expired);
        if (this.state.rowId)
            postData.append("id", this.state.rowId);

        try {
            await axios.post("https://api.firestation.online/admin/gallery/" + (this.state.rowId ? "edit" : "add"), postData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': (User.isLogin() ? "Bearer " + Cookies.get('token') : ""),
                }
            }).then((res) => {
                const error = res.data.error;
                const success = res.data.success;

                if (success) {
                    d.setState({ errorMessage: "", open: false, dataLoaded: false });
                    d.getData();
                    return;
                }

                d.setState({ errorMessage: error });
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleDelete = async (id) => {
        let d = this;
        try {
            await Req("admin/gallery/delete", "post", { "id": id }).then(function(data) {
               if (data.success) {
                   d.setState({ dataLoaded: false });
                   d.getData();
               } 
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    handleSearch = (value) => {
        this.setState({ dataLoaded: false });
        const filteredRows = this.state.defaultRows.filter((row) => {
            console.log(row);
            return row.name.toLowerCase().includes(value.toLowerCase()) || row.userName.toLowerCase().includes(value.toLowerCase()) || row.email.toLowerCase().includes(value.toLowerCase()) || row.phone.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({ rows: filteredRows, dataLoaded: true });
    };

    getData = async () => {
        let loadedData = [];
        let e = this;
        try {
            await Req("admin/gallery", "get").then(function(data) {
                if (data.success) {
                    data.data.forEach((e) => {
                        loadedData.push(createData(
                            e.id,
                            e.url,
                            e.status,
                            e.expired,
                            (e.preventDelete ? true : false),
                        ));
                    });
                }
            });
            e.setState({
                defaultRows: loadedData,
                rows: loadedData,
                dataLoaded: true
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    
    // פעולה ראשונה שקוראת שהדף נפתח
    componentDidMount() {
        this.getData();
    }
    
    render() {
        return (
            <GalleryContext.Provider value={{
                ...this.state,
                handleProps: this.handleProps,
                handleSearch: this.handleSearch,
                handleEdit: this.handleEdit,
                closeEdit: this.closeEdit,
                setData: this.setData,
                handleAdd: this.handleAdd,
                handleSubmit: this.handleSubmit,
                handleDelete: this.handleDelete,
                handleImage: this.handleImage,
            }}>
                {this.props.children}
            </GalleryContext.Provider>
        );
    }
}

export { GalleryContext, GalleryProvider }