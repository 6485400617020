import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Cookies, Req, User } from '../../functions';
import { Base64 } from 'js-base64';
import axios from 'axios';

const GuidesContext = React.createContext({});

const GuidesProvider = (props) => {
    const [ dataLoaded, setIsLoading ] = useState(false);
    const [data, setData] = useState({});
    const [submitted, setButtonSubmitted] = useState(false);

    const columns = [
        { id: 'title', label: 'שם המאמר', flex: 1 },
        { id: 'imgUrl', label: 'תמונה', flex: 1 },
        { id: 'status', label: 'סטטוס (מוצג/לא מוצג)', flex: 1 },
        { id: 'options', label: 'אפשרויות', flex: 1 }
    ];

    const [rows, setRows] = useState([]);
    const { id } = useParams();

    const getData = async () => {
        setButtonSubmitted(false);
        setIsLoading(false);
        await Req('admin/guides' + (id !== undefined ? '/' + id : ''), 'get').then((res) => {
            const success = res.success;
            if (!success)
                return;

            if (id !== undefined)
                setData(res.data);
            else
                setRows(res.data);

            setIsLoading(true);
        });
    };

    const addNewItem = () => {
        let items = {...data};
        const itemData = {
            id: Math.floor( Math.random() * 100000 * 1.75),
            title: "פריט חדש",
            data: "",
        };
        items.data.push(itemData);
        setData(items);
    };

    const deleteItem = (row) => {
        let items = {...data};
        let item = [...data.data];

        const tempData = [];
        item.filter((obj, i) => i !== row).map((line, i) => {
            updateSetValue('data[' + i + '][title]', line.title);
            updateSetValue('data[' + i + '][data]', Base64.decode(line.data));
            return tempData.push(line);
        });
        items.data = tempData;
        setData(items);
        console.log(items.data);
    };

    useEffect(() => {
        getData();
    }, [id]);

    const handleImage = async (files) => {
        const postData = new FormData();
        postData.append("image", files[0]);
        postData.append("guideId", id);
 
        try {
            await axios.post("https://api.firestation.online/admin/guides/upload", postData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': (User.isLogin ? "Bearer " + Cookies.get('token') : ""),
                },
            }).then((res) => {
                const success = res.data.success;
                if (!success) {
                    return;
                }
                getData();
            });
        }
        catch (error) {
            console.log(error);
        }
    };

    const { control: update, handleSubmit: handleUpdateSubmit, setValue: updateSetValue } = useForm({ mode: "onChange" });
    let navigate = useNavigate();

    const handleUpdate = async (updatedData) => {
        updatedData['id'] = parseInt(id);
        if (updatedData.data === undefined || !updatedData.data.length)
        {
            window.alert("עליך להזין מקטע אחד לפחות!");
            return;
        }
        setButtonSubmitted(true);
        
        Object.keys(updatedData.data).map((i) => {
            const item = updatedData.data[i];
            return updatedData.data[i]['data'] = Base64.encode(item['data']);
        });

        try {
            await Req("admin/guides/edit", "post", updatedData).then((res) => {
                const success = res.success;
                if (!success) {
                    setButtonSubmitted(false);
                    return;
                }

                navigate('/guides');
                setButtonSubmitted(false);
            });
        }
        catch (error) {
            setButtonSubmitted(false);
            console.log(error);
        }
    };
    
    return (
        <GuidesContext.Provider
            value={{
                columns,
                rows,
                setRows,
                getData,
                dataLoaded,
                data,
                addNewItem,
                submitted,
                deleteItem,
                // Update
                handleUpdateSubmit,
                handleUpdate,
                update,
                updateSetValue,
                handleImage,
            }}
        >
            {props.children}
        </GuidesContext.Provider>
    )
}

export { GuidesProvider, GuidesContext }